import Modal from "react-modal";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

export default function ForgotPasswordModal({ isOpen, onClose }) {
  const modalContentRef = useRef(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const handleClickOutsideModal = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();

    let data = JSON.stringify({
      email: email,
    });

    let config = {
      method: "post",
      url: "https://torofx.appsimagicallp.com/users/recover",
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Password reset link sent to your email");
        onClose();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to send password reset link");
      });
  };
  return (
    <>
      <h2>Forgot Password</h2>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Login Modal"
        className="login-modal"
        overlayClassName={"modal-overlay"}
      >
        <div
          className="modal-content signup-modal"
          ref={modalContentRef}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close Button */}
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h2>Forgot Password</h2>
          <form onSubmit={handleReset}>
            <div className="form-group">
              <label>Email*</label>

              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              style={{
                background: "#09ffcf",
                opacity: "0.65",
                color: "black",
                width: "100%",
              }}
            >
              Send Reset Link
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}
