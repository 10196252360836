import React, { useState } from "react";
import "./Courses.css";
import { useNavigate } from "react-router-dom";
import userStore from "../../stores/userStore";
import axios from "axios";
import toast from "react-hot-toast";
import AuthUser from "../AuthUser/AuthUser";

export default function CourseCard({ course, priceID, indx }) {
  const { user, token } = userStore((state) => state.user) ?? {};
  const navigate = useNavigate();
  const { enrollments } = user ?? {};
  const isEnrolled = enrollments?.includes(course.id);
  const [authType, setAuthType] = useState("");

  const courseData = {
    0: {
      type: "Beginners",
      bg: "rgb(0 0 0 / 64%)",
      emi_price: "£150",
      save: "£100",
    },
    1: {
      type: "Elite",
      bg: "rgb(0 0 0 / 64%)",
      emi_price: "£500",
      save: "£500",
    },
  };

  const handleEnroll = async (e) => {
    e.preventDefault();

    if (isEnrolled) return;
    if (user) {
      // navigate("/checkout?priceId=" + priceId);

      let data = JSON.stringify({
        priceId: priceID,
      });

      let config = {
        method: "post",
        url: "https://torofx.appsimagicallp.com/payment/paymentlink",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const { url } = response.data;
          if (url) window.location.href = url;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            navigate("/?auth=login");
          } else {
            toast.error("Something went wrong");
            console.log(error);
          }
        });
    } else {
      setAuthType("login");
    }
  };
  return (
    <div className="course-card">
      {/* <div className="course-thumbnail">
        <img src={course.thumbnail} alt="Course Thumbnail" />
        <div className="enroll-button ">
          <button
            onClick={handleEnroll}
            style={{ fontStyle: isEnrolled ? "italic" : "normal" }}
          >
            {isEnrolled ? "Enrolled" : "Buy Now"}
          </button>
        </div>
        <div className="course-price-label">
          <p style={{ fontStyle: isEnrolled ? "italic" : "normal" }}>
            {isEnrolled ? "Already Enrolled" : ` £${course.price}`}
          </p>
        </div>
      </div> */}
      <div className="course-bg">
        <img src="/Assets/logo.png" alt="logo" />
      </div>
      <div
        className="course-details"
        style={{ background: courseData[indx].bg }}
      >
        <h1 style={{ textAlign: "center", width: "100%" }}>
          {courseData[indx].type}
        </h1>
        <h2 style={{ marginBottom: "10px", marginTop: "5px" }}>
          Toro<span style={{ color: "rgb(78, 252, 255)" }}>FX</span> Course
        </h2>
        <div className="course-desc">
          <div className="course-points">
            <p>
              {" "}
              Toro<span style={{ color: "rgb(78, 252, 255)" }}>FX</span> Course
              TRADING COMMUNITY
            </p>
            <p>Interact with other students and traders.</p>
          </div>
          <div className="course-points">
            <p>45+ INTERACTIVE QUIZZES</p>
            <p>Make sure you have grasped the material.</p>
          </div>
          <div className="course-points">
            <p>82 PRACTICAL ASSIGNMENTS</p>
            <p>Practice right away.</p>
          </div>
          <div className="course-points">
            <p>INDIVIDUAL HOMEWORK CHECKING</p>
            <p>Get professional insight.</p>
          </div>
          <div className="course-points">
            <p> PERSONAL LIVE ASSISTANT</p>
            <p>Throughout the program.</p>
          </div>
          <div className="course-points">
            <p> FINAL EXAM AT THE END</p>
            <p>Test your knowledge.</p>
          </div>
          <div className="course-points">
            <p> DIGITAL GPA LOCKED ON ACCOUNT </p>
            <p>Feel the achievement.</p>
          </div>
          <div className="course-pricing">
            {/* <h4 style={{ color: "#52c4cf" }}>Payment Plan</h4>
            <p style={{ fontSize: "14px", marginBottom: "8px" }}>
              {courseData[indx].emi_price}{" "}
              <span style={{ color: "rgb(40 153 164)" }}>
                PER/MTH FOR 4 MONTH
              </span>{" "}
            </p>
            <button style={{ fontStyle: isEnrolled ? "italic" : "normal" }}>
              {isEnrolled ? "Enrolled" : "Enroll Now"}
            </button>
            <p
              style={{
                color: "rgb(40 153 164)",
                fontSize: "14px",
                marginTop: "8px",
              }}
            >
              or
            </p> */}
            <p style={{ color: "rgb(40 153 164)", fontSize: "14px" }}>
              One Time Payment
            </p>
            <p style={{ fontSize: "14px", marginBottom: "8px" }}>
              <span style={{ color: "white" }}>£{course.price}</span>{" "}
              <span style={{ color: "rgb(82, 196, 207)" }}>
                (<span style={{ color: "rgb(82, 196, 207)" }}>SAVE </span>
                <span style={{ color: "white" }}>{courseData[indx].save}</span>)
              </span>
            </p>
            <button
              onClick={handleEnroll}
              style={{
                fontStyle: isEnrolled ? "italic" : "normal",
                border: "1px solid #ffffff",
                padding: "15px 35px",
              }}
              className="btn-2"
            >
              {" "}
              {isEnrolled ? "Already Enrolled" : "Get Started"}
            </button>
          </div>
        </div>
        {/* <p>{course.programDescription}</p> */}
      </div>
      <AuthUser authType={authType} setAuthType={setAuthType} />
    </div>
  );
}
