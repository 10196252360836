import React, { useEffect, useState } from "react";
import userStore from "../../stores/userStore";
import axios from "axios";
import Footer from "../Footer/Footer";
import CourseCard from "./CourseCard";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

const Courses = () => {
  const { user, token } = userStore((state) => state.user) ?? {};
  const [courses, setCourses] = useState([
    {
      programTitle: "Basic ToroFX Course",
      programDescription: "program",
      createdBy: {
        _id: "6564f2c007f9ed3decf7e676",
        photo: "https://www.w3schools.com/howto/img_avatar.png",
        username: "admin",
        name: "Test ID",
        email: "dism.bly@gmail.com",
        code: 1701114560506,
        id: "6564f2c007f9ed3decf7e676",
      },
      files: [],
      thumbnail:
        "https://media.licdn.com/dms/image/D4D12AQHPFmBB5q0hAg/article-cover_image-shrink_720_1280/0/1680610663851?e=2147483647&v=beta&t=Nzk0zjT-ZY525bRlvXcX_tV44Csy5PgF5JWwcWwqqik",
      currency: "GBP",
      price: 500,
      status: "published",
      type: "paid",
      createdAt: "2023-12-27T17:49:24.174Z",
      backgroundColor: "#197a0a",
      courses: [
        {
          courseTitle: "Chapter 1",
          courseDescription: "Basics Knowledge of Trading",
          thumbnail:
            "https://www.accuwebhosting.com/blog/wp-content/uploads/2022/06/Forex-VPS-min-scaled.jpg",
          islocked: false,
          chapters: [
            {
              userCompleted: [],
              chapterTitle: "The Basics",
              chapterDescription: "Get started with first Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample1.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 6,
              current: 0,
              islocked: false,
              isvideo: false,
              id: "658c66c49c515c0797c78b49",
            },
            {
              userCompleted: [],
              chapterTitle: "Forex & Trading",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.cdn.digitaloceanspaces.com/torofx/library/TOROFXAnimation.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 8,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "658c68009c515c0797c78b6f",
            },
            {
              chapterTitle: "The Investment",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample3.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 77,
              current: 0,
              islocked: true,
              isvideo: false,
              userCompleted: ["6623cfca4cbdbafb1f2df216"],
              id: "658c690e9c515c0797c78bd0",
            },
            {
              userCompleted: [],
              chapterTitle: "The Investment",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample1.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 6,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "659041799587ee1835189a1e",
            },
            {
              userCompleted: [],
              chapterTitle: "The Investment",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample3.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 77,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "659044d85c408eebfb8e1595",
            },
          ],
          id: "658c66229c515c0797c78b3b",
        },
        {
          courseTitle: "Chapter 2",
          courseDescription: "All about Trading",
          thumbnail:
            "https://img-c.udemycdn.com/course/750x422/614472_9613_2.jpg",
          islocked: false,
          chapters: [],
          id: "664d09d6648462f71262f9ad",
        },
        {
          courseTitle: "Chapter 3",
          courseDescription: "All about Trading",
          thumbnail:
            "https://img-c.udemycdn.com/course/750x422/614472_9613_2.jpg",
          islocked: false,
          chapters: [],
          id: "664d09ee648462f71262f9d8",
        },
        {
          courseTitle: "Chapter 4",
          courseDescription: "All about Trading",
          thumbnail:
            "https://img-c.udemycdn.com/course/750x422/614472_9613_2.jpg",
          islocked: false,
          chapters: [],
          id: "664d09f4648462f71262f9eb",
        },
        {
          courseTitle: "Chapter 5",
          courseDescription: "All about Trading",
          thumbnail:
            "https://img-c.udemycdn.com/course/750x422/614472_9613_2.jpg",
          islocked: false,
          chapters: [],
          id: "664d09fa648462f71262f9ff",
        },
        {
          courseTitle: "Chapter 6",
          courseDescription: "All about Trading",
          thumbnail:
            "https://img-c.udemycdn.com/course/750x422/614472_9613_2.jpg",
          islocked: false,
          chapters: [],
          id: "664d0fcb648462f71263f521",
        },
      ],
      enrollments: [
        {
          user: {
            _id: "6564f2c007f9ed3decf7e676",
            photo: "https://www.w3schools.com/howto/img_avatar.png",
            username: "admin",
            name: "Test ID",
            email: "dism.bly@gmail.com",
            code: 1701114560506,
            id: "6564f2c007f9ed3decf7e676",
          },
          enrolledAs: "assistant",
          createdAt: "2023-12-27T17:49:24.177Z",
          id: "658c63a49c515c0797c78b20",
        },
      ],
      isExam: true,
      ExamStatus: "Pass",
      id: "658c63a49c515c0797c78b1f",
    },
    {
      programTitle: "Elite ToroFX Course",
      programDescription: "program",
      createdBy: {
        _id: "6564f2c007f9ed3decf7e676",
        photo: "https://www.w3schools.com/howto/img_avatar.png",
        username: "admin",
        name: "Test ID",
        email: "dism.bly@gmail.com",
        code: 1701114560506,
        id: "6564f2c007f9ed3decf7e676",
      },
      files: [],
      thumbnail: "https://img-c.udemycdn.com/course/750x422/614472_9613_2.jpg",
      currency: "GBP",
      price: 1500,
      status: "published",
      type: "paid",
      createdAt: "2023-12-27T17:49:24.174Z",
      backgroundColor: "#197a0a",
      courses: [
        {
          courseTitle: "Chapter 1",
          courseDescription: "Basics Knowledge of Trading",
          thumbnail:
            "https://ail-do.sgp1.digitaloceanspaces.com/torofx/images/course/program-05.png",
          islocked: false,
          chapters: [
            {
              userCompleted: [],
              chapterTitle: "The Basics",
              chapterDescription: "Get started with first Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample1.mp4",
              thumbnail:
                "https://ail-do.sgp1.cdn.digitaloceanspaces.com/torofx/images/lessons/lesson1.png",
              totalLength: 6,
              current: 0,
              islocked: true,
              isvideo: true,
              id: "60c72b2f9b1e8b6f8b43d293",
            },
            {
              userCompleted: [],
              chapterTitle: "Forex & Trading",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.cdn.digitaloceanspaces.com/torofx/library/TOROFXAnimation.mp4",
              thumbnail:
                "https://ail-do.sgp1.cdn.digitaloceanspaces.com/torofx/images/lessons/lesson1.png",
              totalLength: 6,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "658c68119c515c0797c78b6f",
            },
            {
              userCompleted: [],
              chapterTitle: "The Investment",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample3.mp4",
              thumbnail:
                "https://ail-do.sgp1.cdn.digitaloceanspaces.com/torofx/images/lessons/lesson1.png",
              totalLength: 75,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "658c691e9c515c1797c78bd1",
            },
            {
              userCompleted: [],
              chapterTitle: "The Investment",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample1.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 6,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "659041799587ee1835189a1e",
            },
            {
              userCompleted: [],
              chapterTitle: "The Investment",
              chapterDescription: "Get started with Lesson",
              type: "video",
              url: "https://ail-do.sgp1.digitaloceanspaces.com/torofx/library/sample3.mp4",
              thumbnail: "https://torofx.com/assets/images/torofx_logo.png",
              totalLength: 77,
              current: 0,
              islocked: true,
              isvideo: false,
              id: "659144d85c418eebfb8e1595",
            },
          ],
          id: "658c66229c515c1797c78b3b",
        },
      ],
      enrollments: [
        {
          user: {
            _id: "6564f2c007f9ed3decf7e676",
            photo: "https://www.w3schools.com/howto/img_avatar.png",
            username: "admin",
            name: "Test ID",
            email: "dism.bly@gmail.com",
            code: 1701114560506,
            id: "6564f2c007f9ed3decf7e676",
          },
          enrolledAs: "assistant",
          createdAt: "2023-12-27T17:49:24.177Z",
          id: "658c63a49c515c0797c78b20",
        },
      ],
      isExam: false,
      ExamStatus: "Pass",
      id: "659d85cfa5201f09fdb42a8e",
    },
  ]);
  const navigate = useNavigate();

  const priceIds = {
    "658c63a49c515c0797c78b1f": "price_1OmCI6DTO4m0MY7Cwb7d0jr9",
    "659d85cfa5201f09fdb42a8e": "price_1OmCJ5DTO4m0MY7CErp98N0l",
  };

  const getCourses = () => {
    let config = {
      method: "get",
      url: "https://torofx.appsimagicallp.com/programs",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        if (error?.response && error?.response?.status === 401) {
          user && navigate("/?auth=login");
        }
        console.log(error);
      });
  };
  // useEffect(() => {
  //   // if user is not logged in, redirect to /
  //   if (!user) {
  //     navigate("/?auth=login");
  //   }
  //   getCourses();
  // }, []);

  return (
    <>
      <Header />
      {
        <div
          className="course-container"
          style={{
            backgroundImage: "url('/Assets/bg (3).png')",
          }}
        >
          <h1
            style={{
              color: "white",
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
              marginTop: "15px",
            }}
          >
            Trading Prodigy: <span>Master the Market</span>
          </h1>
          <p
            style={{
              textAlign: "center",
              width: "100%",
              color: "white",
              opacity: "0.6",
              marginBottom: "25px",
              fontSize: "22px",
            }}
          >
            Become a trading prodigy with our course! Unlock advanced strategies
            and conquer the market like never before.
          </p>

          {/* <div className="courses-icon" style={{ width: "100%" }}>
            <img src="/Assets/Mobile in hand 1.png" alt="Mobile in hand" />
          </div> */}

          <div className="courses-card-container">
            {courses?.map((course, index) => (
              <CourseCard
                course={course}
                key={index}
                priceID={priceIds[course.id]}
                indx={index}
              />
            ))}
          </div>
        </div>
      }
      <Footer />
    </>
  );
};

export default Courses;
