// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricingCards {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 70vw;
  height: 40vh;
  margin-bottom: 100px;
  border-radius: 30%;
}
.videoContainer {
  display: flex;
  justify-content: center;
}

.pricingCards .rankCard {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 0;
}

.rankName {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ranking-card {
  display: flex;
  margin-top: 80px;
  overflow-x: scroll;
  width: 100%;
  justify-content: center;
}

.ranking-card-arrow {
  position: absolute;
  z-index: 10;
  top: 30%;
  right: -15px;
}
.ranking-card::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for webkit browsers */
}

@media only screen and (max-width: 768px) {
  .ranking-card {
    justify-content: start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Ranking/Ranking.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,YAAY;AACd;AACA;EACE,aAAa,EAAE,2CAA2C;AAC5D;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".pricingCards {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 50px;\n  width: 70vw;\n  height: 40vh;\n  margin-bottom: 100px;\n  border-radius: 30%;\n}\n.videoContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.pricingCards .rankCard {\n  width: 200px;\n  height: 200px;\n  object-fit: cover;\n  margin-bottom: 0;\n}\n\n.rankName {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.ranking-card {\n  display: flex;\n  margin-top: 80px;\n  overflow-x: scroll;\n  width: 100%;\n  justify-content: center;\n}\n\n.ranking-card-arrow {\n  position: absolute;\n  z-index: 10;\n  top: 30%;\n  right: -15px;\n}\n.ranking-card::-webkit-scrollbar {\n  display: none; /* Hide the scrollbar for webkit browsers */\n}\n\n@media only screen and (max-width: 768px) {\n  .ranking-card {\n    justify-content: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
