import axios from "axios";

export const extranctPlanData = (obj) => {
  const interval = obj?.plan?.interval;
  const isActivePlan = obj?.plan?.active;
  const amount = obj?.plan?.amount;
  const month =
    interval === "year"
      ? +obj?.plan?.interval_count * 12
      : +obj?.plan?.interval_count;
  const id = obj?.plan?.id;
  return { amount, isActivePlan, month, id };
};

export const getuserData = (token) => {
  let config = {
    method: "get",
    url: "https://torofx.appsimagicallp.com/users/me",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
