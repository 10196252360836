import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "./Innovation.css";
import CardMedia from "@mui/material/CardMedia";

const Innovation = () => {
  // Array of innovation data
  const innovations = [
    {
      title: "Psychology",
      description:
        "Our approach goes beyond conventional methods, empowering our members to tap into their psychological strengths.",
      videoSrc:
        "Assets/blue-digital-brain-logo-with-particle-ring-circle-2023-11-27-05-26-20-utc (2).mp4",
    },
    {
      title: "Risk Management",
      description:
        "Navigate the markets with confidence, where calculated decisions and consistent profitability reign supreme.",
      videoSrc:
        "Assets/blue-security-key-logo-with-particle-ring-circle-r-2023-11-27-05-33-22-utc (2).mp4",
    },
    {
      title: "Education",
      description:
        "Experience the difference education makes in trading success. Empower yourself with knowledge to make informed decisions that drive profits",
      videoSrc:
        "Assets/blue-digital-lamp-logo-with-particle-ring-circle-r-2023-11-27-04-54-17-utc (2).mp4",
    },
    {
      title: "Simplicity",
      description:
        "Experience the game-changing simplicity that fuels our trading triumphs. Our streamlined approach empowers traders to effortlessly conquer market complexities, paving the way for unparalleled success.",
      videoSrc:
        "Assets/blue-digital-cloud-computing-logo-with-particle-ri-2023-11-27-05-20-59-utc (2).mp4",
    },
  ];

  return (
    <div className="container">
      <div className="textBox1">
        <h1 className="title-txt">
          The Heart of <span style={{ color: "#4efcff" }}>Innovation</span>
        </h1>
        <strong
          style={{
            fontSize: "25px",
            marginTop: "10px",
            fontFamily: "Geom",
            color: "#FFFFFF",
            opacity: "0.7",
          }}
        >
          "Embrace the Bull's Spirit"
        </strong>
        <p
          className="description"
          style={{
            fontSize: "20px",
            marginBottom: "10px",
            fontFamily: "Geom",
            color: "#FFFFFF",
            opacity: "0.7",
          }}
        >
          Delve into the essence of our trading philosophy, where psychology
          meets bold strategies. Discover your potential, trust your instincts,
          and transform your trading journey with us.
        </p>
      </div>
      <div
        className="cards-container"
        // style={{ marginTop: "100px", display: "flex", width: "100%" }}
      >
        {innovations.map((innovation, index) => (
          <Card
            key={index}
            className="pricing-card innovation-card"
            style={{
              marginRight: "10px",
              height: "410px",
              width: "355px",
              position: "relative",
              fontFamily: "Geom",
              borderRadius: "12px",
              "box-shadow":
                " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            // sx={{ backgroundImage: "url('Assets/EXPERIENCE.png')" }}
          >
            <video
              src="Assets/polygon.mp4"
              autoPlay
              loop
              muted
              playsInline
              style={{
                zIndex: "9",
                position: "absolute",
                fontFamily: "Geom",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            ></video>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                zIndex: "10",
                fontFamily: "Geom",
                position: "relative",
                color: "white",
                background: "rgb(12 64 78 / 45%)",
                height: "100%",
              }}
            >
              <CardMedia
                sx={{ marginTop: "10px" }}
                component="video"
                src={innovation.videoSrc}
                autoPlay
                loop
                muted
                className="videoCard"
                playsInline
              />
              <Typography
                style={{ width: "100%", margin: "10px 0", fontFamily: "Geom" }}
                variant="h4"
                component="div"
                className="title-txt"
              >
                {innovation.title}
              </Typography>
              <Typography
                className="text-base "
                style={{ width: "100%" }}
                variant="body1"
              >
                {innovation.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Innovation;
