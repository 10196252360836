import React from "react";
import "./TermsAndConditions.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const DataProtection = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <h1>DATA PROTECTION POLICY</h1>
        <p>
          We at &nbsp;
          <a data-fr-linked="true" href="//www.torofx.com">
            www.torofx.com
          </a>{" "}
          are committed to protecting your privacy. We have prepared this
          Privacy Policy to describe to you our practices regarding the personal
          data we collect from users of our Platform.
        </p>
        <p>
          &ldquo;Platform&rdquo; means the Website which is owned and operated
          by ToroFX Limited. &ldquo;Users&rdquo; shall mean collectively all the
          users who access our Platform or have created an account on our
          Platform.
        </p>
        <p>
          By using the Services, users consent to the collection and use of
          their Personal Data by us. You also represent to us that you have any
          and all authorizations necessary to use these Services including using
          them to process Personal Data. We collect and use the information you
          provide to us, including information obtained from your use of the
          Services. Also, we may use the information that we collect for our
          internal purposes to develop, tune, enhance, and improve our Services,
          and for advertising and marketing consistent with this Privacy Policy.
        </p>
        <p>
          This privacy policy has been compiled to better serve those who are
          concerned with how their &apos;Personal Data&rsquo; is being used
          online. Personal data means data which relate to a living individual
          who can be identified &ndash; (a) from those data, or (b) from those
          data and other information which is in the possession of, or is likely
          to come into the possession of, the data controller, and includes any
          expression of opinion about the individual and any indication of the
          intentions of the data controller or any other person in respect of
          the individual.&nbsp;
        </p>
        <p>
          Please read our privacy policy carefully to get a clear understanding
          of how our Platform collects, uses, protects or otherwise handles
          users&rsquo; Personal Data.
        </p>
        <p>
          This Privacy Policy is intended to inform users about how our Platform
          treats Personal Data that it processes about users. If users do not
          agree to any part of this Privacy Policy, then we cannot provide its
          Services to users and users should stop accessing our services.
        </p>
        <p>
          By using the Services, you acknowledge, consent and agree that we may
          collect, process, and use the information that you provide to us and
          that such information shall only be used by us or third parties acting
          under our direction, pursuant to confidentiality agreements, to
          develop, tune, enhance, and improve the Services.
        </p>
        <p>
          Although we may attempt to notify you when changes are made to this
          Privacy Policy, you are responsible for periodically reviewing any
          changes which may be made to the Policy. We may, in our sole
          discretion, modify or revise the Policy at any time, and you agree to
          be bound by such modifications or revisions.
        </p>
        <h4>INFORMATION WE COLLECT:</h4>
        <ul>
          <li>
            When you register with us, you provide us information about yourself
            &ndash; your full name email address, mobile number, country, date
            of birth, gender and password. If you correspond with us by e-mail,
            we may retain the content of your e-mail messages, your e-mail
            address, and our responses. We also collect general information
            about your use of our services.
          </li>
          <li>
            By using our Platform, you acknowledge and agree that you may be
            transferring your personal data outside of the country in which you
            have contributed it (&ldquo;your country&rdquo;), and that you
            consent to any transfer of information outside of your country.
            These countries may not have similar data protection laws to those
            that apply in your country. However, if we transfer your personal
            data outside of your country in this way, we will take steps to try
            to ensure that your privacy rights are protected in compliance with
            applicable data protection law and this Privacy Policy.
          </li>
        </ul>
        <h4>INFORMATION WE COLLECT AUTOMATICALLY WHEN YOU USE OUR SERVICES:</h4>
        <ul>
          <li>
            When you access or use our Services, we automatically collect
            information about you, including:
          </li>
          <li>
            <strong> Device Information:</strong> We may collect information
            about the device you use to access our Services, including the
            hardware model, operating system and version, unique device
            identifier, phone number, International Mobile Equipment Identity
            (&quot;IMEI&quot;) and mobile network information.&nbsp;
          </li>
          <li>
            <strong>Geo-location Information-</strong> Upon using the Platform,
            you will be asked to turn on location services. If you turn these
            features on, we may collect your device&apos;s geo-location data and
            save your device&apos;s coordinates to offer certain features to
            you. We may also use your device&apos;s geo-location information to
            personalize the Platform and make it easier for you to interact with
            other Users close by. You can control your location information
            settings in your Account settings and switch them off if you want
            to. Even if you have disabled location services, we may still
            determine your city, state, and country location based on your IP
            address (but not your exact location).
          </li>
          <li>
            <strong>
              Information Collected by Cookies and Other Tracking Technologies:
            </strong>
            We use various technologies to collect information, and this may
            include sending cookies to you. Accepting a cookie does not provide
            us access to your device or any Personal Data about you, other than
            the information you choose to share. Other servers cannot read them,
            nor can they be used to deliver a virus. Most browsers automatically
            accept cookies, but you can usually adjust yours (Microsoft Internet
            Explorer, Firefox or Google Chrome) to notify you of cookie
            placement requests, refuse certain cookies, or decline cookies
            completely.&nbsp;
          </li>
          <li>
            &ldquo;Web beacons&rdquo; or clear .gifs are small pieces of code
            placed on a Web page to monitor behavior and collect data about the
            visitors viewing a Web page. For example, Web beacons or similar
            technology can be used to count the users who visit a Platform or to
            deliver a cookie to the browser of a visitor viewing that page. We
            may use Web beacons or similar technology on our Services from time
            to time for this and other purposes.
          </li>
          <li>
            We gather certain information automatically and stores it in log
            files. This information includes internet protocol addresses as well
            as browser, internet service provider, referring/exit pages, search
            terms, operating system, date/time stamp, and click stream data.
            Occasionally, we may connect Personal Information to information
            gathered in our log files, as necessary to improve the Service for
            individual customers. Otherwise, we generally use this information
            as we would any Usage Data, to analyze trends, administer and
            maintain the Service, or track usage of various features within the
            Service.
          </li>
        </ul>
        <h4>HOW WE USE YOUR PERSONAL INFORMATION:</h4>
        <p>
          We use the personal information we collect to fulfill your requests
          for services, improve our services and contact you.
        </p>
        <p>
          By providing us your e-mail address, you consent to us using the
          e-mail address to send you our Platform and services related notices,
          including any notices required by law, in lieu of communication by
          postal mail. You also agree that we may send notifications of activity
          on our Platform to the e-mail address you give us, in accordance with
          any applicable privacy settings. We may use your e-mail address to
          send you other messages, such as newsletters, changes to our features,
          or other information. If you do not want to receive optional e-mail
          messages, you may modify your settings to opt out.
        </p>
        <p>
          Our settings may also allow you to adjust your communications
          preferences. If you do not wish to receive promotional email messages
          from us, you may opt out by following the unsubscribe instructions in
          those emails. If you opt out, you will still receive non-promotional
          emails from us about your account and our Services.
        </p>
        <p>
          Following termination or deactivation of your services, we may (but
          are under no obligation to) retain your information for archival
          purposes. We will not publicly disclose any of your personal data
          other than as described in this Privacy Policy.
        </p>
        <p>
          At our sole discretion, for any reason or no reason at all, we reserve
          the right to remove any content or messages, if we believe that such
          action is necessary (a) to conform to the law, comply with legal
          process served on us, or investigate, prevent, or take action
          regarding suspected or actual illegal activities; (b) to enforce this
          policy, to take precautions against liability, to investigate and
          defend ourselves against any third-party claims or allegations, to
          assist government enforcement agencies, or to protect the security or
          integrity of our Platform; or (c) to exercise or protect the rights,
          property, or personal safety of the Platform, our users, or others.
        </p>
        <p>
          To send other messages that help us provide our services on the Site;
        </p>
        <p>We never show any users where the other user lives.</p>
        <p>We do not share your email address with other users;</p>
        <p>
          By deactivating your account, you can permanently delete all data, for
          which you, or we, cannot gain access again
        </p>
        <p>
          Third Party Service Providers: We employ other companies and
          individuals to perform functions on our behalf. Examples may include
          providing marketing assistance, providing search results and links
          (including paid listings and links), processing credit card payments
          and providing customer service. In connection with their performance
          of functions on our behalf, we may share personally identifiable
          information with such companies and individuals as needed for them to
          perform their functions, but we do not permit such entities and
          individuals to use personally identifiable information for other
          purposes. In addition, we may provide non-personally-identifiable
          information to advertisers and other third parties for their use in
          marketing efforts for us, themselves, or others. If you are not
          interested in receiving targeted advertisements or other marketing
          information or materials as a result of such efforts, you may adjust
          the settings on your mobile devices to prevent the use of your
          non-personally-identifiable information in this manner.
        </p>
        <p>
          In-House Promotions: We may on occasion combine information we receive
          through our Services with outside records to enhance our ability to
          market our services that may be of interest to you. If you have signed
          up to receive our e-mails and prefer not to receive marketing
          information in connection with our Services, please follow the
          &ldquo;unsubscribe&rdquo; instructions provided on any marketing
          e-mail you receive in connection with our Services or by following the
          instructions in our Email Opt Out Form, as described below under
          &ldquo;Unsubscribe Requests&rdquo;.
        </p>
        <p>
          Business Transfers: As our businesses continue to evolve, we might
          sell one or more of our companies, subsidiaries or business units. In
          such transactions, personally identifiable information generally is
          one of the transferred business assets. In such event, this Privacy
          Policy may be amended as set forth below or the collection and uses of
          your personally identifiable information may be governed by a
          different privacy policy.
        </p>
        <p>
          Protection of Our Services and Others: We reserve the right to release
          personally identifiable information to unaffiliated third parties when
          we believe its release is appropriate to comply with the law, enforce
          or apply our Terms and Conditions and other agreements, or protect the
          rights, property or safety of us, our users or others. This includes
          exchanging information with other unaffiliated third parties in
          connection with fraud protection and credit risk reduction.
        </p>
        <p>
          With Your Consent: Other than as set out above, you will receive
          notice and have the opportunity to withhold consent when personally
          identifiable information about you might be shared with unaffiliated
          third parties.
        </p>
        <p>
          We may transmit the user data across the various Platforms of the
          Company.
        </p>
        <p>
          User Submissions: If you participate in any online forum/communities,
          chat sessions and/or blogs, or otherwise post in any user comment
          field visible to other users of our Services, any information that you
          submit or post will be visible to and may be read, collected or used
          by others who use our Services. We are not responsible for any
          information, including personally identifiable information, you choose
          to submit in any such user comment field.
        </p>
        <h4>INFORMATION SHARED DURING TRANSACTION</h4>
        <p>
          You agree that you will enter into transactions with third parties
          through our Platform and will share your Personal Information with
          them for easy completion of the transaction. You hereby expressly
          agree that we shall not be involved or held liable for any breach of
          the privacy or security of that data. The said breach, if any, shall
          be a matter of dispute between you and the third party and we shall
          not be held liable or be issued a notice for the same. &nbsp;
        </p>
        <p>
          We STRONGLY recommend that you should be careful and vigilant while
          disclosing your Personal Information with your transaction partner.
          Please do not disclose your bank and account details to anyone through
          our Platform or to any individual whom you have met through our
          Platform. &nbsp;
        </p>
        <p>
          We do not take credit card/debit card or other bank details on our
          Platform. For payment you will be directed to sign in through the
          respective payment gateways integrated on our Platform and the
          transaction would be completed therein. It is to be noted that we will
          not be storing any Bank related information on our records and none of
          our staffs will hold or be exposed to this information.
        </p>
        <h4>HOW WE SHARE YOUR INFORMATION:</h4>
        <p>
          As a matter of policy, we will not sell or rent information about you
          and we will not disclose information about you in a manner
          inconsistent with this Privacy Policy except as required by law or
          government regulation. We cooperate with law enforcement inquiries, as
          well as other third parties, to enforce laws such as those regarding
          intellectual property rights, fraud and other personal rights.
        </p>
        <p>
          We will not share the personal information we collect about you with
          any third party for their own marketing purposes without your consent.
          We have contract with third parties prohibiting them from sharing your
          personal data.
        </p>
        <p>
          We collate statistics about site traffic, sales and other commercial
          information which we pass onto third parties to assist us with
          improving the services we provide to you. We also use demographic
          information to tailor the Site and we share that information with
          third parties so that they can build up a better picture of our
          customer base and general consumer trends.
        </p>
        <p>
          If you do not agree with our Privacy Policy, Terms of Service or other
          policies on our Platform, please discontinue use of our Service; your
          continued usage of the Service will signify your assent to and
          acceptance of our Privacy Policy and Terms of Use.
        </p>
        <p>
          WE CAN (AND YOU AUTHORIZE US TO) DISCLOSE ANY INFORMATION ABOUT YOU TO
          LAW ENFORCEMENT, OTHER GOVERNMENT OFFICIALS, ANY LAWSUIT OR ANY OTHER
          THIRD PARTY THAT WE, IN OUR SOLE DISCRETION, BELIEVE NECESSARY OR
          PLATFORMROPRIATE IN CONNECTION WITH AN INVESTIGATION OF FRAUD,
          INTELLECTUAL PROPERTY INFRINGEMENT, OR OTHER ACTIVITY THAT IS ILLEGAL
          OR MAY EXPOSE US, OR YOU, TO LIABILITY.&nbsp;
        </p>
        <h4>ENSURING INFORMATION IS ACCURATE AND UP-TO-DATE</h4>
        <p>
          We take reasonable precautions to ensure that the Personal Data we
          Collect, Use and Disclose is complete, relevant and up-to-date.
          However, the accuracy of that information depends to a large extent on
          the information you provide. That&apos;s why we recommend that you:
        </p>
        <p>Let us know if there are any errors in your Personal Data; and</p>
        <p>
          Keep us up-to-date with changes to your Personal Data such as your
          name or address.&nbsp;
        </p>
        <h4>RIGHTS OF THE DATA SUBJECT:</h4>
        <p>
          <strong>Right of confirmation:</strong> Each data subject shall have
          the right granted by the European legislator to obtain from the
          controller the confirmation as to whether or not personal data
          concerning him or her are being processed. If a data subject wishes to
          avail himself of this right of confirmation, he or she may, at any
          time, contact any employee of the controller.
        </p>
        <p>
          <storng>Right of access: </storng> Each data subject shall have the
          right granted by the European legislator to obtain from the controller
          free information about his or her personal data stored at any time and
          a copy of this information. Furthermore, the European directives and
          regulations grant the data subject access to the following
          information:
        </p>
        <ul>
          <li>the purposes of the processing;</li>
          <li>the categories of personal data concerned;</li>
          <li>
            the recipients or categories of recipients to whom the personal data
            have been or will be disclosed, in particular recipients in third
            countries or international organizations;
          </li>
          <li>
            where possible, the envisaged period for which the personal data
            will be stored, or, if not possible, the criteria used to determine
            that period;
          </li>
          <li>
            the existence of the right to request from the controller
            rectification or erasure of personal data, or restriction of
            processing of personal data concerning the data subject, or to
            object to such processing;
          </li>
          <li>
            the existence of the right to lodge a complaint with a supervisory
            authority;
          </li>
          <li>
            where the personal data are not collected from the data subject, any
            available information as to their source;
          </li>
          <li>
            the existence of automated decision-making, including profiling,
            referred to in Article 22(1) and (4) of the GDPR and, at least in
            those cases, meaningful information about the logic involved, as
            well as the significance and envisaged consequences of such
            processing for the data subject.
          </li>
          <li>
            Furthermore, the data subject shall have a right to obtain
            information as to whether personal data are transferred to a third
            country or to an international organisation. Where this is the case,
            the data subject shall have the right to be informed of the
            appropriate safeguards relating to the transfer. If a data subject
            wishes to avail himself of this right of access, he or she may, at
            any time, contact any employee of the controller.
          </li>
        </ul>
        <p>
          <strong>Right to rectification:</strong> Each data subject shall have
          the right granted by the European legislator to obtain from the
          controller without undue delay the rectification of inaccurate
          personal data concerning him or her. Taking into account the purposes
          of the processing, the data subject shall have the right to have
          incomplete personal data completed, including by means of providing a
          supplementary statement. If a data subject wishes to exercise this
          right to rectification, he or she may, at any time, contact any
          employee of the controller.
        </p>
        <p>
          <strong>Right to erasure (Right to be forgotten):</strong> Each data
          subject shall have the right granted by the European legislator to
          obtain from the controller the erasure of personal data concerning him
          or her without undue delay, and the controller shall have the
          obligation to erase personal data without undue delay where one of the
          following grounds applies, as long as the processing is not necessary:
        </p>
        <ul>
          <li>
            The personal data are no longer necessary in relation to the
            purposes for which they were collected or otherwise processed.
          </li>
          <li>
            The data subject withdraws consent to which the processing is based
            according to the point (a) of Article 6(1) of the GDPR, or point (a)
            of Article 9(2) of the GDPR, and where there is no other legal
            ground for the processing.
          </li>
          <li>
            The data subject objects to the processing pursuant to Article 21(1)
            of the GDPR and there are no overriding legitimate grounds for the
            processing, or the data subject objects to the processing pursuant
            to Article 21(2) of the GDPR.
          </li>
          <li>The personal data have been unlawfully processed.</li>
          <li>
            The personal data must be erased for compliance with a legal
            obligation in Union or Member State law to which the controller is
            subject.
          </li>
          <li>
            The personal data have been collected in relation to the offer of
            information society services referred to in Article 8(1) of the
            GDPR.
          </li>
          <li>
            If one of the aforementioned reasons applies, and a data subject
            wishes to request the erasure of personal data stored by us, he or
            she may, at any time, contact any employee of the controller. Any of
            our Employees shall promptly ensure that the erasure request is
            complied with immediately.
          </li>
          <li>
            Where the controller has made personal data public and is obliged
            pursuant to Article 17(1) to erase the personal data, the
            controller, taking account of available technology and the cost of
            implementation, shall take reasonable steps, including technical
            measures, to inform other controllers processing the personal data
            that the data subject has requested erasure by such controllers of
            any links to, or copy or replication of, those personal data, as far
            as processing is not required. Any of our employees will arrange the
            necessary measures in individual cases.
          </li>
        </ul>
        <h4>Right of restriction of processing:</h4>
        <p>
          Each data subject shall have the right granted by the European
          legislator to obtain from the controller restriction of processing
          where one of the following applies:
        </p>
        <p>
          The accuracy of the personal data is contested by the data subject,
          for a period enabling the controller to verify the accuracy of the
          personal data. The processing is unlawful and the data subject opposes
          the erasure of the personal data and requests instead of the
          restriction of their use instead. The controller no longer needs the
          personal data for the purposes of the processing, but they are
          required by the data subject for the establishment, exercise or
          defence of legal claims. The data subject has objected to processing
          pursuant to Article 21(1) of the GDPR pending the verification whether
          the legitimate grounds of the controller override those of the data
          subject. If one of the aforementioned conditions is met, and a data
          subject wishes to request the restriction of the processing of
          personal data stored by us, he or she may at any time contact any
          employee of the controller. Our Employees will arrange the restriction
          of the processing.
        </p>
        <h4>Right to data portability:</h4>
        <p>
          Each data subject shall have the right granted by the European
          legislator, to receive the personal data concerning him or her, which
          was provided to a controller, in a structured, commonly used and
          machine-readable format. He or she shall have the right to transmit
          those data to another controller without hindrance from the controller
          to which the personal data have been provided, as long as the
          processing is based on consent pursuant to point (a) of Article 6(1)
          of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract
          pursuant to point (b) of Article 6(1) of the GDPR, and the processing
          is carried out by automated means, as long as the processing is not
          necessary for the performance of a task carried out in the public
          interest or in the exercise of official authority vested in the
          controller.
        </p>
        <p>
          Furthermore, in exercising his or her right to data portability
          pursuant to Article 20(1) of the GDPR, the data subject shall have the
          right to have personal data transmitted directly from one controller
          to another, where technically feasible and when doing so does not
          adversely affect the rights and freedoms of others.
        </p>
        <p>
          In order to assert the right to data portability, the data subject may
          at any time contact any of our employee.
        </p>
        <h4>Right to object:</h4>
        <p>
          Each data subject shall have the right granted by the European
          legislator to object, on grounds relating to his or her particular
          situation, at any time, to processing of personal data concerning him
          or her, which is based on point (e) or (f) of Article 6(1) of the
          GDPR. This also applies to profiling based on these provisions. We
          shall no longer process the personal data in the event of the
          objection, unless we can demonstrate compelling legitimate grounds for
          the processing which override the interests, rights, and freedoms of
          the data subject, or for the establishment, exercise or defence of
          legal claims. If we process personal data for direct marketing
          purposes, the data subject shall have the right to object at any time
          to the processing of personal data concerning him or her for such
          marketing. This applies to profiling to the extent that it is related
          to such direct marketing. If the data subject objects us to the
          processing for direct marketing purposes, we will no longer process
          the personal data for these purposes. In addition, the data subject
          has the right, on grounds relating to his or her particular situation,
          to object to processing of personal data concerning him or her by us
          for scientific or historical research purposes, or for statistical
          purposes pursuant to Article 89(1) of the GDPR, unless the processing
          is necessary for the performance of a task carried out for reasons of
          public interest.
        </p>
        <p>
          In order to exercise the right to object, the data subject may contact
          any employee of our Company. In addition, the data subject is free in
          the context of the use of information society services, and
          notwithstanding Directive 2002/58/EC, to use his or her right to
          object by automated means using technical specifications.
        </p>
        <h4>Automated individual decision-making, including profiling:</h4>
        <p>
          Each data subject shall have the right granted by the European
          legislator not to be subject to a decision based solely on automated
          processing, including profiling, which produces legal effects
          concerning him or her, or similarly significantly affects him or her,
          as long as the decision (1) is not is necessary for entering into, or
          the performance of, a contract between the data subject and a data
          controller, or (2) is not authorised by Union or Member State law to
          which the controller is subject and which also lays down suitable
          measures to safeguard the data subject&apos;s rights and freedoms and
          legitimate interests, or (3) is not based on the data subject&apos;s
          explicit consent. If the decision (1) is necessary for entering into,
          or the performance of, a contract between the data subject and a data
          controller, or (2) it is based on the data subject&apos;s explicit
          consent, we shall implement suitable measures to safeguard the data
          subject&apos;s rights and freedoms and legitimate interests, at least
          the right to obtain human intervention on the part of the controller,
          to express his or her point of view and contest the decision.
        </p>
        <p>
          If the data subject wishes to exercise the rights concerning automated
          individual decision-making, he or she may, at any time, contact any
          our employee.
        </p>
        <h4>Right to withdraw data protection consent:</h4>
        <p>
          Each data subject shall have the right granted by the European
          legislator to withdraw his or her consent to the processing of his or
          her personal data at any time.
        </p>
        <p>
          If the data subject wishes to exercise the right to withdraw the
          consent, he or she may, at any time, contact any of our employees.
        </p>
        <p>
          <strong> Right to request access:</strong> You also have a right to
          access information we hold about you. We are happy to provide you with
          details of your Personal Information that we hold or process. To
          protect your personal information, we follow set storage and
          disclosure procedures, which mean that we will require proof of
          identity from you prior to disclosing such information. You can
          exercise this right at any time by contacting us on the above details.
        </p>
        <p>
          <stron>Right to withdraw consent:</stron> Where the legal basis for
          processing your personal information is your consent, you have the
          right to withdraw that consent at any time by contacting us on the
          above details.
        </p>
        <strong>YOUR CHOICES ABOUT YOUR INFORMATION:</strong>
        <p>
          You have several choices regarding use of information on our Services:
        </p>
        <p>
          Email Communications: We will periodically send you free newsletters
          and e- mails that directly promote the use of our Platform, or
          Services. When you receive newsletters or promotional communications
          from us, you may indicate a preference to stop receiving further
          communications from us and you will have the opportunity to
          &ldquo;opt-out&rdquo; by following the unsubscribe instructions
          provided in the e-mail you receive or by contacting us directly.
          Despite your indicated e-mail preferences, we may send you service
          related communications, including notices of any updates to our Terms
          of Use or Privacy Policy.
        </p>
        <p>
          Changing or Deleting Your Personal Data: You may change any of your
          personal information by visiting the Platform and following the
          directions therein or by emailing us at{" "}
          <a data-fr-linked="true" href="mailto:contact@torofx.com">
            contact@torofx.com
          </a>
          . &nbsp;You may request deletion of your personal data by us, and we
          will use commercially reasonable efforts to honor your request, but
          please note that we may be required to keep such information and not
          delete it (or to keep this information for a certain time, in which
          case we will comply with your deletion request only after we have
          fulfilled such requirements). When we delete any information, it will
          be deleted from the active database, but may remain in our archives.
          We may also retain your information for fraud prevention or similar
          purposes.
        </p>
        <p>
          You may, of course, decline to submit personal data through the
          Platform, in which case, we may not be able to provide our services to
          you. You can review and correct the information about you that we keep
          on file by editing your account settings or by contacting us directly
          at{" "}
          <a data-fr-linked="true" href="mailto:contact@torofx.com">
            contact@torofx.com
          </a>
          .
        </p>
        <h4>DATA BREACH</h4>
        <p>
          In the event of a data breach, we will promptly assess the breach,
          notify the relevant authorities, and take appropriate measures to
          mitigate the impact on data subjects.
        </p>
        <h4>DATA PROTECTION OFFICER</h4>
        <p>
          We have appointed a Data Protection Officer (DPO) who is responsible
          for overseeing data protection matters. You can contact the DPO at
          [DPO&apos;S EMAIL ADDRESS].
        </p>
        <h4>CHANGES TO OUR PRIVACY POLICY:</h4>
        <p>
          We may update this Privacy Policy and information security procedures
          from time to time. If these privacy and/or information security
          procedures materially change at any time in the future, we will post
          the new changes conspicuously on the Platform to notify you and
          provide you with the ability to opt out in accordance with the
          provisions set forth above.
        </p>
        <p>
          Continued use of our Platform and Service, following notice of such
          changes shall indicate your acknowledgement of such changes and
          agreement to be bound by the terms and conditions of such changes.
        </p>
        <h4>BREACH OF PRIVACY POLICY</h4>
        <p>
          We reserve the right to terminate or suspend any account or delete
          certain contents from any profile or public domain within the ambit of
          this Platform if the said account or content is found to be in
          violation of our privacy policy. We sincerely request you to respect
          privacy and secrecy concerns of others. The jurisdiction of any breach
          or dispute shall be determined in accordance with the terms of use of
          the Platform.
        </p>
        <h4>NO RESERVATIONS:</h4>
        <p>
          We do not accept any reservation or any type of limited acceptance of
          our privacy policy. You expressly agree to each and every term and
          condition as stipulated in this policy without any exception
          whatsoever.&nbsp;
        </p>
        <h4>NO CONFLICT:</h4>
        <p>
          The policy constitutes a part of the user terms. We have taken utmost
          care to avoid any inconsistency or conflict of this policy with any
          other terms, agreements or guidelines available on our Platform. In
          case there exists a conflict, we request you to kindly contact us for
          the final provision and interpretation. &nbsp;
        </p>
        <h4>CONTACT US:</h4>
        <p>
          If you have any questions about this Privacy Policy, our practices
          relating to the Platform, or your dealings with us, please contact us
          at{" "}
          <a data-fr-linked="true" href="mailto:contact@torofx.com">
            contact@torofx.com
          </a>
          .
        </p>
        <br />
        <br />{" "}
      </div>
      <Footer />
    </>
  );
};

export default DataProtection;
