// EyeIcon.js
import React from 'react';

const EyeIcon = ({ onClick , className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 24"
    width="24"
    height="24"
    className={className}
    onClick={onClick} // Ensure onClick event is added to the SVG element
  >
    <path d="M12 4C6 4 2 12 2 12s4 8 10 8 10-8 10-8S18 4 12 4zm0 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
  </svg>
);

export default EyeIcon;
