import React from "react";

import "./TermsAndConditions.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Disclaimer = () => {
  return (
    <>
      <Header />
      <h1
        style={{ width: "100%", margin: "25px 0 10px 0", textAlign: "center" }}
      >
        DISCLAIMER
      </h1>
      <div className="terms-container">
        <p>
          <a data-fr-linked="true" href="//www.torofx.com">
            www.torofx.com
          </a>{" "}
          (hereinafter referred to as &ldquo;Platform&rdquo; or
          &ldquo;Website&rdquo;, &ldquo;Us&rdquo;, &ldquo;We&rdquo;) offers
          forex trading services and educational content and does not take into
          consideration the Users (hereinafter referred to as
          &nbsp;&ldquo;You&rdquo;, &ldquo;User&rdquo;) own trading experiences,
          personal objectives and goals, financial means, or risk tolerance. If
          you have any concerns, we suggest you seek advice from a professional
          financial advisor. Keep in mind that past performance is no indication
          of future results.
        </p>
        <p>
          User understands and agrees that any information or material and/ or
          services obtained through the Platform is done at User&apos;s own
          discretion and risk and that User will be solely responsible for any
          damage resulting from any transaction.
        </p>
        <p>
          No advice or information, whether oral or written, obtained by user
          from us for free or through or from the service shall create any
          warranty not expressly stated herein.
        </p>
        <p>
          Any trading done by the User in relation to the educational content
          shall be the responsibility of the User and we shall in no way be held
          liable for any acts or actions done by the User.
        </p>
        <p>
          Client must thoroughly understand the basic knowledge and related
          risks of forex trading.
        </p>
        <p className="bold-txt">
          UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO NEGLIGENCE, SHALL
          THE WEBSITE, ITS PARENTS, AFFILIATES, SUBSIDIARIES, SUPPLIERS, AGENTS,
          DIRECTORS, OFFICERS, EMPLOYEES, REPRESENTATIVES, SUCCESSORS, LICENSORS
          OR ASSIGNS BE LIABLE TO YOU OR ANY THIRD PERSON FOR DAMAGES OF ANY
          KIND, WHETHER BASED IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE,
          INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES EVEN IF THE
          WEBSITE HAS BEEN ADVISED SPECIFICALLY OF THE POSSIBILITY OF SUCH
          DAMAGES, ARISING IN ANY WAY FROM OR IN CONNECTION WITH THIS SITE, THE
          SERVICE, USE OF OR INABILITY TO USE THE SERVICE OR ANY LINKS OR ITEMS
          ON THE SERVICE OR ANY PROVISION OF THE TOS, SUCH AS, BUT NOT LIMITED
          TO, LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS.
          APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
          OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. IN NO EVENT SHALL THE
          WEBSITE&rsquo;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND
          CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING BUT NOT
          LIMITED TO, NEGLIGENCE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR
          ACCESSING THIS SITE.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
