import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import userStore from "../../stores/userStore";
import PaymentForm from "./PaymentForm";
import "./Payment.css";
import { CircularProgress } from "@mui/material";

export default function Payment() {
  const publishableKey = process.env.REACT_APP_STRIPE_KEY;
  const stripePromise = loadStripe(publishableKey);
  const [clientSecret, setClientSecret] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = userStore((state) => state.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const priceID = searchParams.get("priceID");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !priceID) {
      navigate("/");
    }

    let data = JSON.stringify({
      priceID,
    });

    let config = {
      method: "post",
      url: "https://torofx.appsimagicallp.com/subscription/create-subscription",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const { paymentIntent } = response.data;
        setClientSecret(paymentIntent);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [priceID, user.token]);

  return (
    <div className="payment-form-container">
      {loading ? (
        <div className="checkout-loader">
          {" "}
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="error-message">
          <h3>Error: {error}</h3>
          <button onClick={() => navigate("/")}>Back to home</button>
        </div>
      ) : (
        <>
          <h2>Complete your payment</h2>
          {stripePromise && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm />
            </Elements>
          )}
        </>
      )}
    </div>
  );
}
