import create from "zustand";

const userStore = create((set) => ({
  user: null,
  setUser: (user) => {
    set({ user });
    localStorage.setItem("user", JSON.stringify(user)); // Save user to session storage
  },
  clearUser: () => {
    set({ user: null });
    localStorage.removeItem("user"); // Remove user from session storage
  },
}));

// Load user details from session storage on store initialization
const savedUser = localStorage.getItem("user");
if (savedUser) {
  userStore.setState({ user: JSON.parse(savedUser) });
}

export default userStore;
