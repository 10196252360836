import React from "react";
import "./ChatFab.css";
import ChatIcon from "@mui/icons-material/Chat";
export default function ChatFAB() {
  return (
    <div className="chat-fab">
      {" "}
      <ChatIcon />
    </div>
  );
}
