import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MembershipCarousel from "../Carousel copy/MembershipCarousel";
import Innovation from "../Innovation/Innovation";
import Features from "../Features/Features";
import Ranking from "../Ranking/Ranking";
import Playstore from "../Playstore/Playstore";
import Pricing from "../Pricing/Pricing";
import ChatFAB from "../ChatFAB/ChatFAB";

const Membership = () => {
  return (
    <>
      <Header />
      <MembershipCarousel />
      <Innovation />
      <Features />
      <Ranking />
      <Pricing />
      <Playstore />
      <Footer />
      {/* <ChatFAB /> */}
    </>
  );
};

export default Membership;
