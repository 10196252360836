import React, { useEffect, useState } from "react";
import "./MemberShipDetails.css";
import userStore from "../../stores/userStore";
import axios from "axios";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

export default function MemberShipDetails() {
  const { user, token, subscriptionDetails } =
    userStore((state) => state.user) ?? {};
  const completeUserData = userStore((state) => state.user);
  const setUser = userStore((state) => state.setUser);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [cancelInProgress, setCancelInProgress] = useState(false);

  const getMemberDetails = () => {
    if (user) {
      const { id } = user;
      let data = JSON.stringify({
        customerId: id,
      });

      let config = {
        method: "post",
        url: "https://torofx.appsimagicallp.com/subscription/subscriptionsByID",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const userData = {
            ...completeUserData,
            subscriptionDetails: response.data,
          };
          setUser(userData);
          localStorage.setItem("user", JSON.stringify(userData));
        })
        .catch((error) => {
          setError(error?.response?.data?.error);
          if (
            error?.response?.data?.error === "No active subscriptions found"
          ) {
            const userData = {
              ...completeUserData,
              subscriptionDetails: null,
            };
            setUser(userData);
            localStorage.setItem("user", JSON.stringify(userData));
          }
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            navigate("/?auth=login");
          }
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getMemberDetails();
  }, []);

  function getDateParts(timestamp) {
    if (!timestamp) return;
    const date = new Date(timestamp);

    const year = date.getFullYear();

    const monthIndex = date.getMonth();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[monthIndex];

    const day = date.getDate();

    return `${year} ${month} ${day}`;
  }

  const handleClick = () => {
    navigate("/#pricing");
  };

  const cancelSubscription = async (e) => {
    setCancelInProgress(true);
    e.stopPropagation();
    const { subscriptionId } = subscriptionDetails;
    if (user) {
      let data = JSON.stringify({
        subscriptionId,
      });

      let config = {
        method: "post",
        url: "https://torofx.appsimagicallp.com/subscription/cancel-subscription",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setTimeout(() => {
            getMemberDetails();
            toast.success("Subscription cancelled successfully");
            setCancelInProgress(false);
          }, 3000);
        })
        .catch((error) => {
          toast.error("Something went wrong");
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            navigate("/?auth=login");
          }
          setCancelInProgress(false);
        });
    } else {
      navigate("/?auth=login");
    }
  };
  return (
    (subscriptionDetails || error) && (
      <div className="membership-details-container">
        <h2 style={{ color: "white", marginTop: 0, marginBottom: "5px" }}>
          Membership <span style={{ color: "rgb(78, 252, 255)" }}>Details</span>
        </h2>
        <div className="membership-details-card">
          {error ? (
            <>
              {" "}
              <div className="membership-details">
                <p>No plan is subscribed</p>
              </div>
            </>
          ) : (
            <>
              <div className="membership-details">
                <p>Plan: {subscriptionDetails?.plan?.interval}</p>
                <p style={{ margin: "0 5px" }}>
                  {" "}
                  <strong style={{ color: "rgb(78, 252, 255)" }}>|</strong>{" "}
                </p>
                <p>
                  Next Payment Due On:{" "}
                  {getDateParts(subscriptionDetails?.current_period_end)}
                </p>
              </div>
            </>
          )}

          <hr />
          <div className="membership-details">
            <div className="card-footer" onClick={handleClick}>
              <h4 style={{ color: "rgb(78, 252, 255)", fontWeight: 500 }}>
                {subscriptionDetails ? "Change Membership" : "Join Membership"}
              </h4>
            </div>
            {subscriptionDetails && (
              <>
                <p style={{ margin: "0 5px" }}>
                  {" "}
                  <strong style={{ color: "white" }}>|</strong>{" "}
                </p>

                <div className="card-footer" onClick={cancelSubscription}>
                  {cancelInProgress ? (
                    <CircularProgress
                      size={16}
                      style={{ color: "rgb(78, 252, 255)" }}
                    />
                  ) : (
                    <h4 style={{ color: "rgb(78, 252, 255)", fontWeight: 500 }}>
                      Cancel Membership
                    </h4>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
}
