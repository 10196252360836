import { Button } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import toast from "react-hot-toast";

export default function PaymentForm() {
  const elements = useElements();
  const stripe = useStripe();

  const [isProcessing, setIsProcessing] = useState(false);

  const handelSubmit = (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);

    const { error } = stripe.confirmPayment({
      elements,
      confirmParams: { return_url: window.location.origin + "/transaction" },
    });

    if (error) {
      toast.error("Something went wrong");
      console.log({ error });
    }
    setIsProcessing(false);
  };
  return (
    <form id="payment-form" onSubmit={handelSubmit}>
      <PaymentElement />
      <button disabled={isProcessing} id="submit">
        {isProcessing ? "Processing" : "Pay now"}
      </button>
    </form>
  );
}
