import React from "react";
import "./TermsAndConditions.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <h1>TERMS AND CONDITIONS</h1>
        <p>
          Welcome to{" "}
          <a data-fr-linked="true" href="//www.torofx.com">
            www.torofx.com
          </a>{" "}
          (hereinafter referred to as the &ldquo;Platform&rdquo; or
          &ldquo;Website&rdquo;, &ldquo;Site&rdquo;, &ldquo;We&rdquo;,
          &ldquo;Us&rdquo;, &ldquo;Our&rdquo;), owned and operated by ToroFX
          Limited (hereinafter referred to as &ldquo;the Company&rdquo;) with
          its registered office located at 91 Sidcup High Street, Sidcup, DA14
          6DH. The Platform is offered to you conditioned on your acceptance
          without modification of the terms, conditions, and notices contained
          herein (the &quot;Terms&quot;).&nbsp;
        </p>
        <p>
          The Platform provides forex trading and educational contents
          (hereinafter referred to as &ldquo;Services&rdquo;) to the Users
          (hereinafter referred to as &ldquo;You&rdquo; or &ldquo;Your&rdquo; or
          &ldquo;User&rdquo;).
        </p>
        <p>
          BY CLICKING ON THE &quot;ACCEPT&quot; BUTTON AT THE END OF THE
          AGREEMENT ACCEPTANCE FORM, USERS AGREE TO BE BOUND BY THE TERMS AND
          CONDITIONS OF THIS AGREEMENT. PLEASE READ THIS ENTIRE AGREEMENT
          CAREFULLY BEFORE ACCEPTING ITS TERMS. WHEN YOU UNDERTAKE ANY ACTIVITY
          ON THE PLATFORM YOU AGREE TO ACCEPT THESE TERMS AND CONDITIONS.
        </p>
        <p>
          PLEASE READ THE TERMS OF THIS AGREEMENT CAREFULLY AS THEY CONTAIN
          IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
          OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AND A
          CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES.
        </p>
        <p>
          IN USING THIS PLATFORM, YOU ARE DEEMED TO HAVE READ AND AGREED TO THE
          FOLLOWING TERMS AND CONDITIONS SET FORTH HEREIN. ANY INCIDENTAL
          DOCUMENTS AND LINKS MENTIONED SHALL BE CONSIDERED TO BE ACCEPTED
          JOINTLY WITH THESE TERMS. YOU AGREE TO USE THE PLATFORM ONLY IN STRICT
          INTERPRETATION AND ACCEPTANCE OF THESE TERMS AND ANY ACTIONS OR
          COMMITMENTS MADE WITHOUT REGARD TO THESE TERMS SHALL BE AT YOUR OWN
          RISK. THESE TERMS AND CONDITIONS FORM PART OF THE AGREEMENT BETWEEN
          THE USERS AND US. BY ACCESSING THIS PLATFORM, AND/OR UNDERTAKING TO
          PERFORM A SERVICE BY US INDICATES YOUR UNDERSTANDING, AGREEMENT TO AND
          ACCEPTANCE, OF THE DISCLAIMER NOTICE AND THE FULL TERMS AND CONDITIONS
          CONTAINED HEREIN.
        </p>
        <h4>DEFINITIONS:</h4>
        <p>
          &ldquo;Company&rdquo; shall mean ToroFX Limited which owns and
          operates the Platform,{" "}
          <a data-fr-linked="true" href="//www.torofx.com">
            www.torofx.com
          </a>
          .
        </p>
        <p>
          &ldquo;User&rdquo; shall mean individuals who visit our platform and
          engage in our &ldquo;Service&rdquo;.
        </p>
        <p>
          &ldquo;Content&rdquo; means the content the company creates and make
          available in connection with the Services including, but not limited
          to, visual interfaces, interactive features, graphics, design,
          compilation, computer code, products, software, functionalities,
          aggregate ratings, reports and other usage-related data in connection
          with activities associated with your account and all other elements
          and components of the Services excluding Your Content and third party
          content. It will include (but is not limited to) images, photos,
          audio, video, and all other forms of information or data.
        </p>
        <p>
          &nbsp;&ldquo;Services&rdquo; denotes the services offered by us to the
          users through our Platform such as providing forex trading and
          educational contents.
        </p>
        <h4>INTERPRETATION:</h4>
        <p>The official language of these terms shall be English.&nbsp;</p>
        <p>
          The headings and sub-headings are merely for convenience purpose and
          shall not be used for interpretation.&nbsp;
        </p>
        <h4>ELIGIBILITY:</h4>
        <p>
          You may use the Service only if you are at least eighteen (18) years
          of age and can form a binding contract with us, and only in compliance
          with this Agreement and all applicable local, state, national, and
          international laws, rules and regulations.&nbsp;
        </p>
        <p>
          Any use or access to the Service by anyone under 18 is strictly
          prohibited and in violation of this Agreement. Our Platform reserves
          the right to refuse to provide you with access to the Platform if we
          discover that you are under the age of 18 years. The Service is not
          available to any Users previously removed from the Service by us,
          unless we provide such Users with specific written authorization to
          re-use the Service.
        </p>
        <p>
          By becoming a User, you represent and warrant that you are at least 18
          years old and that you have the right, authority and capacity to enter
          into and abide by the terms and conditions of this Agreement.&nbsp;
        </p>
        <p>
          Unauthorized Users are strictly prohibited from accessing or
          attempting to access, directly or indirectly, the Platform. Any such
          unauthorized use is strictly forbidden and shall constitute a
          violation of applicable laws.
        </p>
        <p>
          Our Platform may, in its sole discretion, refuse to offer access to or
          use of the Platform to any person or entity and change its eligibility
          criteria at any time. This provision is void where prohibited by law
          and the right to access the Platform is revoked in such jurisdictions.
        </p>
        <h4>SERVICES:</h4>
        <p>
          The Company offers the users with a platform providing forex trading
          and educational contents.
        </p>
        <h4>
          SERVICES WHICH ARE PROHIBITED OR RESTRICTED FROM BEING USED ON
          COMPANY&rsquo;S PLATFORM:
        </h4>
        <p>
          The Company shall not be providing services on/via its Platform. Thus,
          the User shall not use the following services through our Platform.
        </p>
        <ul>
          <li>
            Adult Material which includes pornography and other sexually
            suggestive materials (including literature, imagery and other
            media);
          </li>
          <li>
            Alcohol including alcoholic beverages such as beer, liquor, wine or
            champagne;
          </li>
          <li>
            Animals and Wildlife Products - examples include mounted specimens,
            and ivory
          </li>
          <li>Artifacts prohibited for sale</li>
          <li>Beta Software</li>
          <li>Bootleg/Pirated Recordings</li>
          <li>Brand Name Misuse</li>
          <li>
            Bulk marketing tools which include email lists, software, or other
            products enabling unsolicited email messages (spam)
          </li>
          <li>
            Cable descramblers and black boxes which includes devices intended
            to obtain cable and satellite signals for free
          </li>
          <li>Catalogue and URL Sales</li>
          <li>
            Child pornography which includes pornographic materials involving
            minors
          </li>
          <li>
            Copies of unauthorized Copyrighted materials whether in electronic
            or physical form or any other medium which may not be in existence
            at present
          </li>
          <li>Counterfeit Currency and Stamps</li>
          <li>
            Drugs and drug paraphernalia which includes illegal drugs and drug
            accessories, including herbal drugs
          </li>
          <li>
            Drug test circumvention aids which includes drug cleansing shakes,
            urine test additives, and related items
          </li>
          <li>Embargoed Goods</li>
          <li>
            Endangered species which includes plants, animals or other organisms
            (including product derivatives) in danger of extinction
          </li>
          <li>Event Tickets</li>
          <li>Firearms, Ammunition, Militaria and Knives</li>
          <li>
            Fireworks, Explosives and Explosive Substances; toxic, flammable and
            radioactive materials and substances
          </li>
          <li>
            Gaming/gambling which includes lottery tickets, sports bets,
            memberships/enrollment in online gambling sites, and related content
          </li>
          <li>
            Government IDs and Licenses which includes fake Ids, passports,
            diplomas and noble titles
          </li>
          <li>
            Hacking and cracking materials which includes manuals, how-to
            guides, information, or equipment enabling illegal access to
            software, servers, watomites, or other protected property
          </li>
          <li>Hazardous and Restricted Items</li>
          <li>Human Parts and Remains</li>
          <li>
            Items Encouraging Illegal Activity – examples include an eBook
            describing how to create methamphetamine
          </li>
          <li>
            Miracle cures which includes unsubstantiated cures, remedies or
            other items marketed as quick health fixes
          </li>
          <li>
            Copyright unlocking items that includes Mod Chips or other devices
            designed to circumvent copyright protection
          </li>
          <li>Movie Prints</li>
          <li>
            Offensive Material - examples include ethnically or racially
            offensive material or any literature, products or other materials
            that: i) Defame or slander any person or groups of people based on
            race, ethnicity, national origin, religion, sex, or other factors
            ii) Encourage or incite violent acts iii) Promote intolerance or
            hatred
          </li>
          <li>
            Offensive goods, crime which includes crime scene photos or items,
            such as personal belongings, associated with criminals
          </li>
          <li>Police, Army, Navy and Air force Related Items</li>
          <li>Pre-Sale Listings</li>
          <li>
            Prescription drugs or herbal drugs or any kind of online pharmacies
            which includes drugs or other products requiring a prescription by a
            licensed medical practitioner
          </li>
          <li>Promotional Item</li>
          <li>Real Estate</li>
          <li>
            Regulated goods which includes air bags; batteries containing
            mercury; Freon or similar substances/refrigerants,
            chemical/industrial solvents, government uniforms, car titles or
            logos, license plates, police badges and law enforcement equipment,
            lock-picking devices, pesticides; postage meters, recalled items,
            slot machines; goods regulated by government or other agency
            specifications
          </li>
          <li>
            Replica and Counterfeit Items including imitations of designer
            goods; items without a celebrity endorsement that would normally
            require such an association, fake autographs, counterfeit stamps,
            and other potentially unauthorized goods
          </li>
          <li>Ringtones</li>
          <li>Stocks and Other Securities or related financial products</li>
          <li>Stolen Property</li>
          <li>Surveillance Equipment</li>
          <li>
            Tobacco and cigarettes includes cigarettes, cigars, chewing tobacco,
            and related products
          </li>
          <li>
            Traffic devices which includes radar detectors/jammers, license
            plate covers, traffic signal changers, and related products
          </li>
          <li>
            Weapons which includes firearms, ammunition, knives, brass knuckles,
            gun parts, and other armaments
          </li>
          <li>
            Wholesale currency which includes discounted currencies or currency
            exchanges
          </li>
          <li>
            Any other product or service which is not in compliance with all the
            applicable laws and regulations whether federal, state, local or
            international including the English Laws.
          </li>
          <li>
            The Company reserves the right to modify, update and alter the list
            from time to time without any prior intimation.
          </li>
          <li>
            The Company also reserves the right to modify the listings if
            relevant details are missing and it may even remove the
            inappropriate listings on the Platform without any prior intimation.
          </li>
        </ul>
        <h4>COVENANTS OF USER:</h4>
        <p>The User hereby covenants with the Company as under:</p>
        <p>
          The User agrees to indemnify and keep indemnified the Company from all
          claims/losses (including attorney fee for defending/prosecuting any
          case) that may arise against the Company due to acts/omission on the
          part of the User.
        </p>
        <p>
          To ensure and not to provide any description/image/text/graphic which
          is unlawful, illegal, intimidating, obnoxious, objectionable, obscene,
          vulgar, opposed to public policy, prohibited by law or morality or is
          in violation of intellectual property rights including but not limited
          to Trademark and copyright of any third party or of inaccurate, false,
          incorrect, misleading description or is derogatory in nature. Further
          it will forward the product description and image only for the product
          which is offered for sale through the Platform of the Company. The
          User agrees that in case there is violation of this covenant, it shall
          do and cause to be done all such acts as are necessary to prevent
          disrepute being caused to the Company.
        </p>
        <p>
          The User shall at all time during the pendency of this agreement
          endeavor to protect and promote the interests of the Company and
          ensure that third parties rights including intellectual property
          rights are not infringed.
        </p>
        <p>WARRANTIES, REPRESENTATION AND UNDERTAKINGS OF USER:</p>
        <p>The User warrants and represents that: -</p>
        <p>
          All obligations narrated under this Agreement are legal, valid,
          binding and enforceable in law against User.
        </p>
        <p>
          There are no proceedings pending against the User, which may have a
          material adverse effect on its ability to perform and meet the
          obligations under this Agreement;
        </p>
        <p>
          It shall, at all times ensure compliance with all the requirements
          applicable for the purposes of this agreement including but not
          limited to Intellectual Property Rights, Tax, etc. It further declares
          and confirms that it has paid and shall continue to discharge all its
          obligations towards statutory authorities.
        </p>
        <p>
          That it has adequate rights under relevant laws including but not
          limited to various Intellectual Property Legislation(s) to enter into
          this Agreement with the Company and perform the obligations contained
          herein and that it has not violated/ infringed any intellectual
          property rights of any third party.
        </p>
        <p>
          User agrees that appropriate disclaimers and terms of use on
          Company&rsquo;s Platform shall be placed by the Company.
        </p>
        <h4>INTELLECTUAL PROPERTY RIGHTS:&nbsp;</h4>
        <p>
          The company&rsquo;s Platform and other Platforms, and the information
          and materials that it contains, is the property of the company and its
          licensors, and is protected from unauthorized copying and
          dissemination by copyright law, trademark law, international
          conventions, and other intellectual property laws. All company&rsquo;s
          product names and logos are trademarks or registered trademarks.
          Nothing contained on the company&rsquo;s Platform should be
          interpreted as granting, by implication, estoppel, or otherwise, any
          license or right to use the company&rsquo;s Platform or any materials
          displayed on the company&rsquo;s Platform, through the use of framing
          or otherwise, except: (a) as expressly permitted by these Terms and
          Conditions; or (b) with the prior written consent of the company. User
          and User shall not attempt to override or circumvent any of the usage
          rules or restrictions on the Platform.
        </p>
        <p>
          Except as otherwise expressly granted to you in writing, we do not
          grant you any other express or implied right or license to the
          Services, our Content or our Intellectual Property Rights.
        </p>
        <p>
          Proprietary Rights. Subject to the limited rights expressly granted in
          this Agreement, the company reserves all rights, title and interest in
          and to the Service, including all related intellectual property
          rights. No rights are granted to the User and User in this Agreement
          other than as expressly outlined in this Agreement.
        </p>
        <p>
          Pattern Data. Pattern Data means non-personally identifiable
          information, data and reports derived fromor compiled through the
          Service, including but not limited to demographics data, aggregated
          statistics on user conversion, location data and trend data such as
          aggregated data and statistics which may indicate frequency and type
          of use of the Service, and popularity of the Service. For greater
          certainty, Pattern Data is data that does not identify a specific User
          or User, its business contracts or its end users. As between the
          company, User and User, all right and title to Pattern Data belong to
          the company and accordingly the company is free to use Pattern Data
          for any purpose including the improvement of the Service of the
          Platform.
        </p>
        <h4>DATA:</h4>
        <p>
          &quot; Data&quot; means any and all identifiable information about
          Users and their affiliates generated or collected by Company or the
          User, including, but not limited to, customer&rsquo;s name, email
          addresses, services availed, phone numbers, and customer&rsquo;s
          preferences and tendencies. The User agrees that it will only use the
          Data in complying with its obligations in this Agreement.
        </p>
        <p>
          The User represents, warrants, and covenants that it will not resell
          or otherwise disclose any Data to any third party, in whole or in
          part, for any purpose whatsoever.
        </p>
        <p>
          User acknowledge that the Services may contain information which is
          designated as confidential by the company and that you shall not
          disclose such information without company&rsquo;s prior written
          consent.
        </p>
        <p>
          By submitting the Content the User hereby irrevocably grant us a
          perpetual, irrevocable, nonexclusive, royalty-free, right to use the
          Content for any purpose including API partnerships with third parties
          and in any media existing now or in future. User irrevocably waive,
          and cause to be waived, any claims and assertions of moral rights or
          attribution with respect to User&rsquo;s Content brought against us,
          by any third party services and our and their users.
        </p>
        <p>
          We may obtain business addresses, phone numbers, and other contact
          information from third party vendors who obtain their data from public
          sources. We have no control over, and make no representation or
          endorsement regarding the accuracy, relevancy, copyright compliance,
          legality, completeness, timeliness or quality of any product,
          services, advertisements and other content appearing in or linked to
          from the Services
        </p>
        <h4>SERVICE LIMITATIONS:</h4>
        <p>
          The Service is not a backup service and accordingly, the company will
          not be responsible for any lost data due to server crashes or other
          events outside company&rsquo;s reasonable control.
        </p>
        <h4>AUDIT RIGHTS:</h4>
        <p>
          The company shall have the right to use the capabilities of the
          Service to confirm User&rsquo;s and User&rsquo;s compliance with this
          Agreement, including without limitation the fees payable hereunder.
        </p>
        <h4>RELATIONSHIP:</h4>
        <p>
          Nothing in this Agreement will be construed as creating a relationship
          of partnership, joint venture, agency, or employment between the
          Parties. The Company shall not be responsible for the acts or
          omissions of the User and User shall not represent the Company,
          neither does it have any power or authority to speak for, represent,
          bind, or assume any obligation on behalf of the Company.
        </p>
        <h4>INDEMNITY:</h4>
        <p>
          The User indemnifies and shall hold indemnified the Company, its
          partners, officers, employees, representatives, agents from and
          against all losses, damages, claims, suits, legal proceedings and
          otherwise howsoever arising from or in connection with any claim
          including but not limited to claim for any infringement of any
          intellectual property rights or any other rights of any third party or
          of law, concerning quality, quantity and any claim in relation to the
          User&rsquo;s product, the breach of any of the User&rsquo;s
          warranties, representations or undertakings or in relation to the
          non-fulfillment of any of its obligations under this Agreement or
          terms of use of Company&rsquo;s Platform or any obligations arising
          out of the User infringing any applicable laws, regulations including
          but not limited to Intellectual Property Rights, taxes. Etc. This
          clause shall survive the termination or expiration of this Agreement.
        </p>
        <br />
        <h4>LIMITATION OF LIABILITY:</h4>
        <p>
          It is expressly agreed by the User that the Company shall under no
          circumstances be liable or responsible for any loss, injury or damage
          to the User or any other party whomsoever, arising on account of any
          transaction under this Agreement.&nbsp;
        </p>
        <p>
          The User agrees and acknowledges that it shall be solely liable for
          any claims, damages, allegation arising out of the services through
          Company&rsquo;s Platform and shall hold the Company harmless and
          indemnified against all such claims and damages. Further the Company
          shall not be liable for any claims, damages arising out of any
          negligence, misconduct, or misrepresentation by the User or any of its
          representatives.&nbsp;
        </p>
        <p>
          The Company under no circumstances shall be liable to the User for
          loss and/or anticipated loss of profits, or for any direct or
          indirect, incidental, consequential, special or exemplary damages
          arising from the subject matter of this Agreement, regardless of the
          type of claim and even if the User has been advised of the possibility
          of such damages, such as, but not limited to loss of revenue or
          anticipated profits or loss business, unless such loss or damages is
          proven by the User to have been deliberately caused by the Company.
        </p>
        <p>
          Any information provided on our Platform, in our communications, or
          through our services is for informational purposes only and should not
          be considered as financial advice or a recommendation to engage in
          financial transactions.
        </p>
        <p>
          Users should exercise their own judgment and seek professional advice
          from authorized financial institutions or advisors when making
          financial decisions. Reliance on information provided by us through
          the platform is done at the user&apos;s own risk.
        </p>
        <br />
        <h4>TERMINATION:</h4>
        <p>
          This Agreement may be terminated by the Company forthwith in the
          event:-
        </p>
        <p>
          User commits a material breach of any representation, obligations,
          covenant, warranty or term of this agreement and the same is not cured
          within 30 days after written notice given by the Company.&nbsp;
        </p>
        <p>If a Petition for insolvency is filed against the User.&nbsp;</p>
        <p>
          If the User is in infringement of the third party rights including
          intellectual property rights.
        </p>
        <p>
          This agreement may be terminated without reason by either party after
          serving upon the other, a written notice of 30 days. The agreement
          shall stand terminated after expiry of such period.&nbsp;
        </p>
        <h4>EFFECTS OF TERMINATION:</h4>
        <p>
          In the event of termination/expiry of this Agreement, the Company
          shall remove and shall discontinue the services provided to theUser on
          its Platform with immediate effect.
        </p>
        <p>
          Company shall not be liable for any loss or damages (direct, indirect
          or inconsequential) incurred by the User by virtue of termination of
          this agreement.
        </p>
        <p>
          During the period under notice both the parties shall be bound to
          perform its obligations incurred under this agreement and this
          sub-clause shall survive the termination of this agreement.
        </p>
        <h4>GOVERNING LAW AND DISPUTE RESOLUTION:</h4>
        <p>
          This Agreement and any action related thereto will be governed by the
          English Laws without regard to or Platform of its conflict of law
          provisions or User&rsquo;s state or country of residence.&nbsp;
        </p>
        <p>
          User agrees to submit to the exclusive jurisdiction of the courts
          sitting in England and Wales in relation to proceedings arising out of
          this agreement.
        </p>
        <p>
          In case of dispute between Company and the User, the same shall be
          referred to the decision of a Sole Arbitrator to be appointed by the
          Company in accordance with and subject to the provisions of the
          Arbitration laws of England and Wales.
        </p>
        <p>
          The venue of such Arbitration shall be in England and Wales in order
          to deal with the arbitration proceedings and the awards in accordance
          with law.
        </p>
        <p>The decision of the arbitrator shall be final and binding.&nbsp;</p>
        <p>
          The language to be used and all written documents provided in any such
          arbitration shall be in English.
        </p>
        <h4>DISCLAIMERS:</h4>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY AND ITS AFFILIATES,
          AND EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, MEMBERS, EMPLOYEES,
          AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
          WITH THIS AGREEMENT, THE PLATFORM AND ANY USE THEREOF, INCLUDING,
          WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY MAKES NO
          WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF
          THE PLATFORM&apos;S CONTENT OR THE CONTENT OF ANY OTHER PLATFORM
          LINKED TO THE PLATFORM, AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
          ANY (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
          (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
          RESULTING FROM THE USER&apos;S ACCESS TO AND USE OF THE PLATFORM, (C)
          ANY UNAUTHORIZED ACCESS TO OR USE OF COMPANY&apos;S SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE PLATFORM, (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
          MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY,
          AND/OR (F) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
          ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
          CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
          PLATFORM. COMPANY DOES NOT GUARANTEE THE PRIVACY, SECURITY OR
          AUTHENTICITY OF ANY INFORMATION SO TRANSMITTED OVER OR STORED IN ANY
          SYSTEM CONNECTED TO THE INTERNET OR MOBILE DEVICES.&nbsp;
        </p>
        <h4>NOTICES:</h4>
        <p>
          Except as explicitly stated otherwise, any notices given to Company
          shall be given by email to{" "}
          <a data-fr-linked="true" href="mailto:contact@torofx.com">
            contact@torofx.com
          </a>
          . Any notices given to the User shall be to the email address provided
          by the User to the Company at the time of listing (or as such
          information may be updated via the Platform by User from time to
          time).
        </p>
        <p>
          Any notice, demand, or request with respect to this Agreement shall be
          in writing and shall be effective only if it is delivered by email, to
          the address set forth above. Such communications shall be effective
          when they are received by the addressee; but if sent by certified mail
          in the manner set forth above, they shall be effective five (5) days
          after being deposited in the mail. &nbsp;Any party may change its
          address for such communications by giving notice to the other party in
          conformity with this section.
        </p>
        <br />
        <h4>BINDING EFFECT:</h4>
        <p>
          This agreement shall be binding upon, and inure to the benefit of, the
          successors, executors, heirs, representatives, administrators and
          permitted assigns of the parties hereto. &nbsp;
        </p>
        <h4>ASSIGNMENT:</h4>
        <p>
          The User shall have no right to (a) assign this agreement, by
          operation of law or otherwise; or (b) subcontract or otherwise
          delegate the performance of the services without Company&rsquo;s prior
          written consent which may be withheld as Company determines in its
          sole discretion. &nbsp;Any such purported assignment shall be void.
          The Company at its sole discretion may assign this agreement to any
          third party.
        </p>
        <h4>SEVERABILITY:</h4>
        <p>
          If any provision of this agreement shall be found invalid or
          unenforceable, the remainder of this agreement shall be interpreted so
          as best to reasonably effect the intent of the parties.&nbsp;
        </p>
        <h4>INJUNCTIVE RELIEF:</h4>
        <p>
          User acknowledges and agrees that in the event of a breach or
          threatened breach of this agreement by User and User, Company will
          suffer irreparable harm and will therefore be entitled to injunctive
          relief to enforce this agreement.
        </p>
        <h4>AMENDMENT:</h4>
        <p>
          Company may at any time at its sole discretion modify this Agreement
          from time to time, and any such changes will (i) be reflected on the
          Platform, (ii) be effective thirty calendar days after being so posted
          on the Platform, (iii) not apply retroactively, and (iv) not apply
          &nbsp;to any disputes arising prior to the effective date of such
          change. Company shall also post the amended agreement at the address
          of the User. The User agrees to be bound to any such changes and
          understands the importance of regularly reviewing this Agreement as
          updated on the Platform to keeping the User&rsquo;s listing and
          contact information current.
        </p>
        <p>
          Notwithstanding anything to the contrary herein, Company reserves the
          right to, at any time and from time to time, change, alter, modify, or
          discontinue the Platform and (or any part thereof) with or without
          notice. The User agrees that Company shall have no liability to the
          User or to any third party for any change, alteration, modification,
          suspension, discontinuance, or amendment of the Company&rsquo;s
          Platform.
        </p>
        <p>
          Prices of products are subject to change without notice. We reserve
          the right at any time to modify or discontinue the Service (or any
          part or content thereof) without notice at any time. We shall not be
          liable to you or to any third-party for any modification, price
          change, suspension or discontinuance of the Service.
        </p>
        <h4>FORCE MAJEURE:</h4>
        <p>
          Except with regard to payment obligations, either party shall be
          excused from delays in performing or from failing to perform its
          obligations under this contract to the extent the delays or failures
          result from causes beyond the reasonable control of the party,
          including, but not limited to: failures or default of third party
          software, Users, or products; acts of God or of the public enemy;
          foreign governmental actions; strikes; communications, network
          connection, or utility interruption or failure; fire; flood; epidemic;
          and freight embargoes.
        </p>
        <h4>WAIVER OF RIGHTS:</h4>
        <p>
          A failure or delay in exercising any right, power or privilege in
          respect of this agreement will not be presumed to operate as a waiver,
          and a single or partial exercise of any right, power or privilege will
          not be presumed to preclude any subsequent or further exercise, of
          that right, power or privilege or the exercise of any other right,
          power or privilege.
        </p>
        <h4>MISCELLANEOUS:</h4>
        <p>
          Termination of this agreement for any cause whatsoever shall not
          release a party from any liability which, at the time of termination,
          has already accrued to the other party or which may thereafter accrue
          in respect of any act or omission prior to such termination.
        </p>
        <p>
          Article headings are inserted for convenience of reference only and
          shall not be deemed to affect the interpretation of this agreement or
          of any clause.
        </p>
        <p>
          Each party shall co-operate with the other and execute and deliver to
          the other such instruments and documents and take such other actions
          as may be reasonably requested from time to time in order to carry
          out, give effect to and confirm their rights and the intended purpose
          of this agreement.
        </p>
        <h4>ENTIRE AGREEMENT:</h4>
        <p>
          This agreement along with the terms of use, privacy policy and other
          policies published on the Platform constitutes the entire
          understanding and agreement of the parties with respect to its subject
          matter and supersedes all prior and contemporaneous agreements or
          understandings, inducements or conditions, express or implied, written
          or oral, between the parties.
        </p>
        <h4>CONTACT US:</h4>
        <p>
          For any further clarification of our Terms and Conditions, please
          write to us at{" "}
          <a data-fr-linked="true" href="mailto:contact@torofx.com">
            contact@torofx.com
          </a>
          .
        </p>{" "}
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
