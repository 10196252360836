// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 135px);
}
.download-title {
  position: absolute;
  top: 20%;
}
.os-img-center {
  height: 150px;
  display: flex;
  align-items: center;
}
.os-img {
  height: 150px;
  width: 150px;
}

.os-img img {
  height: 100%;
  width: 100%;
}

.os-container {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.os-div {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

@media only screen and (max-width: 768px) {
  .os-container {
    /* width: 100%; */
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Download/Download.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,+BAA+B;AACjC;AACA;EACE,kBAAkB;EAClB,QAAQ;AACV;AACA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE;IACE,iBAAiB;IACjB,eAAe;IACf,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":[".download-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  min-height: calc(100vh - 135px);\n}\n.download-title {\n  position: absolute;\n  top: 20%;\n}\n.os-img-center {\n  height: 150px;\n  display: flex;\n  align-items: center;\n}\n.os-img {\n  height: 150px;\n  width: 150px;\n}\n\n.os-img img {\n  height: 100%;\n  width: 100%;\n}\n\n.os-container {\n  display: flex;\n  width: 60%;\n  justify-content: space-between;\n}\n\n.os-div {\n  display: flex;\n  flex-direction: column;\n  row-gap: 15px;\n}\n\n@media only screen and (max-width: 768px) {\n  .os-container {\n    /* width: 100%; */\n    flex-wrap: wrap;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
