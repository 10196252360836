import Modal from "react-modal";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ResetPassword({ isOpen, onClose, passwordResetToken }) {
  const modalContentRef = useRef(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    passwordConfirm: "",
  });

  const [passwordError, setPasswordError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const handleClickOutsideModal = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.passwordConfirm) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://torofx.appsimagicallp.com/users/reset/" + passwordResetToken,
        formData
      );
      // setUser(response.data);
      toast.success("Password reset successfully");
      onClose();
      navigate("/?auth=login");
    } catch (error) {
      console.error("error:", error);
      if (error.response && error.response.status === 500) {
        toast.error("Reset Token Expired");
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Login Modal"
        className="login-modal"
        overlayClassName={"modal-overlay"}
      >
        <div
          className="modal-content signup-modal"
          ref={modalContentRef}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close Button */}
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h2>Forgot Password</h2>
          <form onSubmit={handleReset}>
            <div className="form-group">
              <label style={{ color: "white" }}>Password*</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label style={{ color: "white" }}>Confirm Password*</label>
              <input
                type="password"
                name="passwordConfirm"
                placeholder="Confirm your password"
                value={formData.passwordConfirm}
                onChange={handleChange}
                required
              />
            </div>
            {passwordError && <p className="error-message">{passwordError}</p>}
            <button
              type="submit"
              style={{
                background: "#09ffcf",
                opacity: "0.65",
                color: "black",
                width: "100%",
              }}
            >
              Reset
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}
