// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #114654;
  color: white;
  padding: 20px 0;
  font-family: "Geom", sans-serif;
  box-shadow: 0px -1px 0px 2px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #4efcff;
}
.footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  margin-left: 50px;
}
.footer li {
  margin: 0 10px;
  cursor: pointer;
}

.footer li {
  color: white;
  text-decoration: none;
  list-style-type: none;
}

.footer li:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .footer ul {
    margin-left: 0;
  }
  .footer ul li {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,+BAA+B;EAC/B,+CAA+C;EAC/C,6BAA6B;AAC/B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,qBAAqB;EACrB,0BAA0B;EAC1B,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".footer {\n  background-color: #114654;\n  color: white;\n  padding: 20px 0;\n  font-family: \"Geom\", sans-serif;\n  box-shadow: 0px -1px 0px 2px rgba(0, 0, 0, 0.2);\n  border-top: 1px solid #4efcff;\n}\n.footer ul {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  text-decoration: none;\n  text-transform: capitalize;\n  margin-left: 50px;\n}\n.footer li {\n  margin: 0 10px;\n  cursor: pointer;\n}\n\n.footer li {\n  color: white;\n  text-decoration: none;\n  list-style-type: none;\n}\n\n.footer li:hover {\n  text-decoration: underline;\n}\n\n@media only screen and (max-width: 768px) {\n  .footer ul {\n    margin-left: 0;\n  }\n  .footer ul li {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
