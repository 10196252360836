import React from "react";
import "./Playstore.css";
import Chat from "../Chat/Chat";

const Playstore = () => {
  return (
    <>
      <section style={{ backgroundImage: "url('/Assets/bg (3).png')" }}>
        <div className="textbox1 full-width">
          <h1 className="title-txt">DOWNLOAD OUR APPS</h1>
          <p style={{ fontSize: "20px", marginBottom: "20px" }}>
            Explore our apps with FREE access to innovation Markets.
          </p>
          <div className="grid">
            {/* <img src="/Assets/app-store.svg" alt="App Store Button" /> */}
            {/* <img src="/Assets/googleplay.svg" alt="Google Play Button" /> */}
            <img src="/Assets/Download for macOS.svg" alt="MacOS Button" />
            <img src="/Assets/download for windows.svg" alt="Windows Button" />
          </div>
        </div>
        <div className="phone">
          <img
            className="top-phone"
            src="/Assets/iPhone-Mockups-top.png"
            style={{
              height: "80%",
              width: "80%",
              // marginTop: "350px",
              zIndex: "99",
            }}
            alt="iPhone Mockup"
          />
          <img
            className="bottom-phone"
            src="/Assets/iPhone-Mockups-top.png"
            style={{
              height: "80%",
              width: "80%",
              transform: "rotate(45deg) translateX(-50%)",
              position: "relative",
              // zIndex: "-1",
              // marginLeft: "250px",
              // animation: "tilt 1s",
              // marginTop: "-100px",
            }}
            alt="Rotated iPhone Mockup"
          />
        </div>
        <Chat />
      </section>
    </>
  );
};

export default Playstore;
