import React from "react";
import "./Features.css";
import MemberButton from "../MemberButton/MemberButton";
const Features = () => {
  const sections = [
    {
      imgSrc: "/Assets/Market Updates.png",
      backgroundColor: "#48E1B7",
      number: "2",
      title1: "Market Update",
      title2: "Crypto",
    },
    {
      imgSrc: "/Assets/Weekly.png",
      backgroundColor: "#659DF1",
      number: "1",
      title1: "Weekly",
      title2: "Analysis",
    },
    {
      imgSrc: "/Assets/Archives.png",
      backgroundColor: "#CACF1D",
      number: "4",
      title1: "Archives",
      title2: "Lives",
    },
    {
      imgSrc: "/Assets/Market Updates.png",
      backgroundColor: "#20DDDDBD",
      number: "3",
      title1: "Market Updates",
      title2: "Forex",
    },
  ];
  return (
    <div
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url('/Assets/bg (3).png')",
      }}
    >
      <div className="featuresGrid">
        <div className="textbox">
          <h1 className="title-txt" style={{ color: "white", width: "100%" }}>
            Stay in The <span style={{ color: "#4efcff" }}>Loop!</span>
          </h1>
          <p style={{ fontSize: "20px", color: "#FFFFFF", opacity: "0.7" }}>
            Stay in the loop with our weekly schedule delivering a roadmap of
            upcoming content for the week ahead and prizes for the monthly
            members only giveaway.
          </p>
        </div>
        <div className="phone">
          <img className="move-left" src="/Assets/featured/Tpr4fx_4.png" />
        </div>
      </div>
      <img className="mobile-img" src="/Assets/featured/Tprofx_11.png" />
      <div className="featuresGrid">
        <div className="phone">
          <img className="move-left" src="/Assets/featured/Tprofx_11.png"></img>
        </div>
        <div className="textbox">
          <h1 className="title-txt" style={{ color: "white", width: "100%" }}>
            Packed with Over{" "}
            <span style={{ color: "#4efcff" }}>10 Features!</span>{" "}
          </h1>
          <p style={{ fontSize: "20px", color: "#FFFFFF", opacity: "0.7" }}>
            Join a global network of traders with access to an abundance of
            tools and insights at the click of their fingertips with push
            notification.
          </p>
        </div>
      </div>
      <img className="mobile-img" src="/Assets/Packed with Over.png"></img>
      <div className="featuresGrid">
        <div className="textbox">
          <h1 className="title-txt" style={{ color: "white", width: "100%" }}>
            Tailored for <span style={{ color: "#4efcff" }}>Traders</span>{" "}
          </h1>
          <p style={{ fontSize: "20px", color: "#FFFFFF", opacity: "0.7" }}>
            Our app is packed with helpful guides in our designated 'Start Here'
            tab.
            <br />
            Whether it's trading related , or simply some assistance needed
            navigating your way through our apps, we've created a tonne of FAQ's
            to ensure your journey with innovation Markets Start as smoothley as
            possible.
            <br />
            Start your journey towards trading success now!
          </p>
        </div>
        <div className="phone" >
          <img className="" src="/Assets/mobileinhand.png" style={{width:"auto"}}></img>
        </div>
      </div>
      <img className="mobile-img" src="/Assets/mobileinhand.png"></img>
      <div className="featuresGrid">
        <div className="phone">
          <img className="move-down" src="/Assets/phone_mockup3.png"></img>
        </div>
        <div className="textbox">
          <h1 className="title-txt" style={{ color: "white" }}>
            Streamlined <span style={{ color: "#4efcff" }}>Analysis</span>
          </h1>
          <p style={{ fontSize: "20px", color: "white" }}>
            Immerse yourself in a wealth of video content.
          </p>
          <div className="grid" style={{ marginTop: "50px", width: "95%" }}>
            {sections.map((section, index) => (
              <div
                key={index}
                style={{ position: "relative", textAlign: "center" }}
              >
                <img
                  src={section.imgSrc}
                  alt={section.title1}
                  style={{ width: "100%", height: "auto" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <div
                        style={{
                          backgroundColor: section.backgroundColor,
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <h5 style={{ margin: 0, color: "white" }}>
                          {section.number}
                        </h5>
                      </div>
                    </div>

                    <div style={{ width: "70%", textAlign: "left" }}>
                      <h1
                        className="text-sm ml-10"
                        style={{
                          margin: 0,
                          color: "white",
                          wordBreak: "keep-all",
                          width: "100%",
                        }}
                      >
                        {section.title1}
                      </h1>
                      <h1
                        className="text-medium ml-10"
                        style={{ margin: 0, color: "white" }}
                      >
                        {section.title2}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="btn-container">
            <MemberButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
