import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";
import LoginModal from "../Login/LoginModal";
import Dropdown from "./Dropdown";
import userStore from "../../stores/userStore";
import AuthUser from "../AuthUser/AuthUser";
import axios from "axios";
import { getuserData } from "../../utils";

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [authType, setAuthType] = useState("");
  const { token: userToken, subscriptionDetails } =
    userStore((state) => state.user) ?? {};
  const user = userStore((state) => state.user);
  const completeUserData = userStore((state) => state.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const auth = searchParams.get("auth");
  const setUser = userStore((state) => state.setUser);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const openLogin = () => {
    setAuthType("login");
    // setOpenLoginModal(true);
  };

  const closeLogin = () => {
    // setOpenLoginModal(false);
    setAuthType("");
  };

  useEffect(() => {
    if (auth) {
      openLogin();
    }
  }, [auth]);
  const pathName = window.location.pathname;

  const getMemberDetails = () => {
    if (user) {
      const userDetails = user.user ?? {};
      const { id } = userDetails ?? {};
      let data = JSON.stringify({
        customerId: id,
      });

      let config = {
        method: "post",
        url: "https://torofx.appsimagicallp.com/subscription/subscriptionsByID",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const userData = {
            ...completeUserData,
            subscriptionDetails: response.data,
          };
          setUser(userData);
          // localStorage.setItem("user", JSON.stringify(user Data));
        })
        .catch((error) => {
          if (error?.response && error?.response?.status === 401) {
            navigate("/?auth=login");
          }
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getuserData(userToken).then((response) => {
      if (response) {
        const userData = {
          ...completeUserData,
          user: response,
        };
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        getMemberDetails();
      }
    });
  }, []);

  const menuItems = [
    { path: "/", label: "Home", show: true },
    { path: "/Membership", label: "Memberships", show: true },
    { path: "/Products", label: "Products", show: true },
    { path: "/Programs", label: "Programs", show: true },
    { path: "/Download", label: "Download", show: true },
  ];

  return (
    <div className="container-fluid">
      <AppBar sx={{ background: "#114654", position: "sticky" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
            <List>
              <ListItem
                component={NavLink}
                to="/"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                component={NavLink}
                to="/Membership"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Memberships" />
              </ListItem>
              <ListItem
                component={NavLink}
                to="/Products"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Products" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/Programs"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Programs" />
              </ListItem>
              {
                <ListItem
                  button
                  component={NavLink}
                  to="/Download"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary="Download" />
                </ListItem>
              }
            </List>
          </Drawer>
          <NavLink to="/" className="nav-link">
            <img
              src="/Assets/logo.png"
              alt="logo"
              style={{
                top: "14px",
                left: "208px",
                width: "72px",
                height: "72px",
                marginRight: "10px",
                marginLeft: "100px",
              }}
            />
          </NavLink>
          <ul className="menu" style={{ display: { xs: "none", md: "flex" } }}>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.show && (
                  <li key={index}>
                    <Button
                      color="inherit"
                      component={NavLink}
                      to={item.path}
                      style={{
                        padding: "0",
                        textTransform: "capitalize",
                        color: pathName === item.path ? "#3BFBEC" : "inherit",
                      }}
                    >
                      {item.label}
                    </Button>
                    {pathName === item.path && (
                      <Divider
                        style={{ width: "100%", backgroundColor: "#3BFBEC" }}
                      />
                    )}
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
          {user ? (
            <div className="userIcon">
              <Dropdown />
            </div>
          ) : (
            <Button
              color="inherit"
              onClick={openLogin}
              className="btn login-btn"
              sx={{
                width: "8vw",
                height: "45px",
                borderRadius: "30px",
                background: "#52c4cf",
                margin: "0 50px 0 auto",
                border: "1px solid white",
                minWidth: "100px",
              }}
            >
              Login
            </Button>
          )}
          {/* <Button
            color="inherit"
            onClick={openLogin}
            className="btn"
            sx={{
              width: "8vw",
              height: "45px",
              borderRadius: "30px",
              background: "#52c4cf",
              margin: "0 50px 0 auto",
              border: "2px solid white",
            }}
          >
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
      <AuthUser authType={authType} setAuthType={setAuthType} />
      {/* <LoginModal
        isOpen={openLoginModal}
        onClose={closeLogin}
        authType={authType}
        onForgotPassword={() => {
          console.log("forgot Password");
        }}
      /> */}
    </div>
  );
};

export default Header;
