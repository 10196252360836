import axios from "axios";
import React, { useEffect, useState } from "react";
import userStore from "../../stores/userStore";
import "./UserRanking.css";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

const elite = "/Assets/rank/Elite.png";
const platinum = "/Assets/rank/Platinum.png";
const gold = "/Assets/rank/Gold.png";
const silver = "/Assets/rank/Silver.png";
const bronze = "/Assets/Bronze.png";

const rankingMap = {
  elite: { img: elite, color: "yellow" },
  platinum: { img: platinum, color: "#DEDDDB" },
  gold: { img: gold, color: "gold" },
  silver: { img: silver, color: "silver" },
  bronze: { img: bronze, color: "rgb(199, 123, 48)" },
};

const progressBarWidth = 300; // Adjust the width of the progress bar as needed
const rankingArr = ["bronze", "silver", "gold", "platinum", "elite"];

function getNextRank(currentRank) {
  const currentIndex = rankingArr.indexOf(currentRank);
  if (currentIndex === -1 || currentIndex === rankingArr.length - 1) {
    return null; // Rank not found or already at the highest rank
  }
  return rankingArr[currentIndex + 1];
}

function getOrderedRanks(rank, order) {
  const rankingArr = ["bronze", "silver", "gold", "platinum", "elite"];
  const orderedRanks = [];
  const startIndex = rankingArr.indexOf(rank.toLowerCase());

  if (startIndex === -1) {
    throw new Error("Invalid rank provided");
  }

  if (order.toLowerCase() === "ascending") {
    for (let i = startIndex; i < rankingArr.length; i++) {
      orderedRanks.push({
        rank: rankingArr[i],
        img: rankingMap[rankingArr[i]].img,
        color: rankingMap[rankingArr[i]].color,
      });
    }
  } else if (order.toLowerCase() === "descending") {
    for (let i = startIndex; i >= 0; i--) {
      orderedRanks.push({
        rank: rankingArr[i],
        img: rankingMap[rankingArr[i]].img,
        color: rankingMap[rankingArr[i]].color,
      });
    }
  } else {
    throw new Error("Invalid order provided");
  }
  return order.toLowerCase() === "descending"
    ? orderedRanks.reverse()
    : orderedRanks;
}
export default function UserRanking({ setRankIcon }) {
  const { user, token } = userStore((state) => state.user) ?? {};

  const [rank, setRank] = useState();
  const navigate = useNavigate();

  const getRank = () => {
    let data = JSON.stringify({
      UserId: user.id,
    });

    let config = {
      method: "post",
      url: "https://torofx.appsimagicallp.com/rank/getrankbyuserId",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setRank(response.data);
        setRankIcon(rankingMap[response.data?.level?.toLowerCase()].img);
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate("/?auth=login");
        }
      });
  };

  useEffect(() => {
    user && getRank();
  }, []);

  const renderProgressBar = () => {
    if (!rank) return null;

    const currentPoints = rank?.points; // Get current points
    const nextRankPoints = 1000; // Get points required for next rank

    const progressPercentage = (currentPoints / nextRankPoints) * 100; // Calculate progress percentage

    return (
      <div className="ranking-container">
        {getOrderedRanks(rank?.level?.toLowerCase(), "descending").map(
          (data, index) => (
            <div className="rank-info" key={index}>
              <img
                className={`rank-img ${data?.rank?.toLowerCase()}`}
                src={data.img}
                alt="Current Rank"
              />
              <h6 style={{ color: data.color }}>{data?.rank}</h6>
            </div>
          )
        )}
        <div className="progress">
          {progressPercentage > 12 ? (
            <>
              <div
                className="progress-value"
                style={{ width: progressPercentage + "%" }}
              >
                {rank?.points} points
                <div className="progress-head"></div>
              </div>
            </>
          ) : (
            <div
              className="progress-value"
              style={{ width: progressPercentage + "%", position: "relative" }}
            >
              <span
                style={{
                  position: "absolute",
                  color: "white",
                  top: "-25px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                {rank?.points} points
              </span>
            </div>
          )}
        </div>
        {getOrderedRanks(
          getNextRank(rank?.level?.toLowerCase()),
          "ascending"
        ).map((data) => (
          <div className="rank-info">
            <img
              className={`rank-img ${data?.rank?.toLowerCase()}`}
              src={data.img}
              alt="Current Rank"
            />
            <h6 style={{ color: data.color }}>{data?.rank}</h6>
          </div>
        ))}
      </div>
    );
  };

  return (
    rank && (
      <div className="ranking">
        <div className="current-rank">
          <h2 style={{ color: "white", margin: "0" }}>
            My Current{" "}
            <span style={{ color: "rgb(78, 252, 255)", margin: "0" }}>
              Rank
            </span>
          </h2>
          <img
            className="current-rank-img"
            src={rankingMap[rank?.level?.toLowerCase()]?.img}
          />
          <h3 style={{ color: rankingMap[rank?.level?.toLowerCase()]?.color }}>
            {rank?.level}
          </h3>
        </div>
        <>{renderProgressBar()}</>
      </div>
    )
  );
}
