// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-fab {
  position: fixed;
  bottom: 10%;
  right: 5%;
  height: 60px;
  width: 60px;
  right: 5%;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #52c4cf;
  z-index: 99;
}
.chat-fab svg {
  font-size: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatFAB/ChatFab.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,SAAS;EACT,YAAY;EACZ,WAAW;EACX,SAAS;EACT,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,eAAe;AACjB","sourcesContent":[".chat-fab {\n  position: fixed;\n  bottom: 10%;\n  right: 5%;\n  height: 60px;\n  width: 60px;\n  right: 5%;\n  background-color: white;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 3px solid #52c4cf;\n  z-index: 99;\n}\n.chat-fab svg {\n  font-size: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
