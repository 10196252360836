import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Carousel from "../Carousel/Carousel";
import Pricing from "../Pricing/Pricing";
import Playstore from "../Playstore/Playstore";
import Footer from "../Footer/Footer";
import ChatFAB from "../ChatFAB/ChatFAB";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import userStore from "../../stores/userStore";
import ResetPassword from "../ForgotPasswordModal/ResetPassword";

const LandingPage = () => {
  const location = useLocation();
  const [query, setQuery] = useSearchParams();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const passwordResetToken = searchParams.get("passwordResetToken");
  const setUser = userStore((state) => state.setUser);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      document
        .getElementById(hash.split("#")[1])
        .scrollIntoView({ behavior: "smooth" });
    }

    if (token) {
      (async () => {
        try {
          const response = await axios.post(
            "https://torofx.appsimagicallp.com/users/emaillogin",
            { token }
          );
          setUser(response.data);
          setQuery({});
        } catch (error) {
          console.error("Signup failed:", error);
        }
      })();
    }
    if (passwordResetToken) {
      setResetPassword(true);
    }
  }, []);
  return (
    <>
      <Header />
      <Carousel />
      <Pricing />
      <Playstore />
      <Footer />

      <ResetPassword
        isOpen={resetPassword}
        onClose={() => setResetPassword(false)}
        passwordResetToken={passwordResetToken}
      />
    </>
  );
};

export default LandingPage;
