import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-hot-toast";
import userStore from "../../stores/userStore";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";

const UpdateProfile = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    mobile: "+44",
  });
  const [phoneError, setPhoneError] = useState("");
  const setUser = userStore((state) => state.setUser);
  const { user, token } = userStore((state) => state.user) ?? {};
  const modalContentRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (value, name) => {
    if (name === "mobile" && value) {
      const isValid = isPossiblePhoneNumber(value);
      setPhoneError(isValid ? "" : "Invalid phone number");
    } else if (name === "mobile" && !value) {
      setPhoneError("");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (user) {
      const { mobile, name, username } = user;
      setFormData({ mobile, name, username });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneError) {
      return;
    }
    const config = {
      method: "patch",
      url: " https://torofx.appsimagicallp.com/users/me",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: { ...formData, email: user.email },
    };

    try {
      const response = await axios.request(config);
      setUser(response.data);
      toast.success("Details updated");
      onClose();
    } catch (error) {
      console.error(" failed:", error);
      toast.error("An error occured");
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/?auth=login");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const handleClickOutsideModal = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Update profile"
      className="login-modal"
      overlayClassName={"modal-overlay"}
    >
      {/* Modal Content */}
      <div
        className="modal-content update-profile-modal "
        ref={modalContentRef}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>Update Profile</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label style={{ color: "white" }}>Name*</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={(e) => handleChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="form-group">
            <label style={{ color: "white" }}>Mobile</label>
            <PhoneInput
              international
              defaultCountry={"GB"}
              value={formData.mobile}
              onChange={(value) => handleChange(value, "mobile")}
              countryCallingCodeEditable={false}
              error={phoneError}
            />
            {phoneError && (
              <span style={{ color: "red", marginTop: "8px" }}>
                {phoneError}
              </span>
            )}
          </div>
          <button
            type="submit"
            style={{
              background: "#09ffcf",
              opacity: "0.65",
              color: "black",
              width: "100%",
            }}
          >
            Update
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default UpdateProfile;
