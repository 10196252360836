// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.membership-details-container {
  /* width: 100%; */
}
.membership-details-card {
  background: #85e3fc1f;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-width: 380px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.membership-details-card p {
  color: rgb(255, 255, 255);
  opacity: 0.9;
}
.membership-details {
  display: flex;
  white-space: nowrap;
}

.membership-details-card hr {
  width: 100%;
  height: 2px;
  color: rgb(78 252 255 / 20%);
}
.membership-details-card p {
  color: rgb(251 255 251);
}
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
}
@media (max-width: 768px) {
  .membership-details-card {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1800px) {
  .membership-details-container {
    margin-left: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/MemberShipDetails/MemberShipDetails.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,4EAA4E;AAC9E;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,4BAA4B;AAC9B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,uBAAuB;AACzB;AACA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".membership-details-container {\n  /* width: 100%; */\n}\n.membership-details-card {\n  background: #85e3fc1f;\n  padding: 20px;\n  border-radius: 5px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 10px;\n  min-width: 380px;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\n.membership-details-card p {\n  color: rgb(255, 255, 255);\n  opacity: 0.9;\n}\n.membership-details {\n  display: flex;\n  white-space: nowrap;\n}\n\n.membership-details-card hr {\n  width: 100%;\n  height: 2px;\n  color: rgb(78 252 255 / 20%);\n}\n.membership-details-card p {\n  color: rgb(251 255 251);\n}\n.card-footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n  width: 100%;\n  justify-content: center;\n}\n@media (max-width: 768px) {\n  .membership-details-card {\n    width: 100%;\n  }\n}\n\n@media screen and (min-width: 1024px) and (max-width: 1800px) {\n  .membership-details-container {\n    margin-left: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
