// LoginModal.js
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import EyeIcon from "./EyeIcon"; // Assuming EyeIcon is in a separate file named EyeIcon.js
import "./LoginModal.css";
import { toast } from "react-hot-toast";
import userStore from "../../stores/userStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginModal = ({ isOpen, onClose, onCreateAccount, onforgot }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const user = userStore((state) => state.user);
  const setUser = userStore((state) => state.setUser);
  const { token } = userStore((state) => state.user) ?? {};
  const modalContentRef = useRef(null);
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://torofx.appsimagicallp.com/users/login",
        { email, password }
      );
      setUser(response.data); // Update the user in the store upon successful login
      // console.log("Login successful:", response.data);
      toast.success("Login successful");

      onClose();
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Login failed");
    }
  };

  const handleForgotPassword = () => {
    onforgot();
  };

  useEffect(() => {
    if (user) {
      getMemberDetails();
    }
  }, [user]);

  const getMemberDetails = () => {
    if (user) {
      const { id } = user?.user;
      let data = JSON.stringify({
        customerId: id,
      });

      let config = {
        method: "post",
        url: "https://torofx.appsimagicallp.com/subscription/subscriptionsByID",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setUser({ ...user, subscriptionDetails: response.data });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            navigate("/?auth=login");
          }
          console.log(error);
        });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const handleClickOutsideModal = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Login Modal"
      className="login-modal"
      overlayClassName={"modal-overlay"}
    >
      {/* Video Background */}

      {/* Modal Content */}
      <div
        className="modal-content"
        ref={modalContentRef}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email*</label>

            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <span className="password-label">
              {" "}
              <label style={{ color: "white" }}>Password*</label>{" "}
              <div
                style={{ color: "white" }}
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </div>
            </span>

            <input
              type={showPassword ? "text" : "password"}
              value={password}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            style={{
              background: "#09ffcf",
              opacity: "0.65",
              color: "black",
              width: "100%",
            }}
          >
            Login
          </button>
        </form>
        <span
          className="forgot-password"
          style={{ color: "#09ffcf", opacity: "0.65" }}
          onClick={handleForgotPassword}
        >
          Forgot password?
        </span>

        <span
          className="forgot-password"
          style={{ color: "#09ffcf", opacity: "0.65" }}
          onClick={onCreateAccount}
        >
          Don't have account, create?
        </span>
      </div>
    </Modal>
  );
};

export default LoginModal;
