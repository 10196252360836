import React, { useEffect, useState } from "react";
import userStore from "../../stores/userStore";
import AuthUser from "../AuthUser/AuthUser";
import { useNavigate } from "react-router-dom";
import { extranctPlanData } from "../../utils";

export default function MemberButton() {
  const user = userStore((state) => state.user);
  const [authType, setAuthType] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Become a Member");
  const navigate = useNavigate();
  const handleBtnClcik = () => {
    if (user) {
      if (buttonLabel === "Download App") {
        navigate("/Download");
        return;
      }
      const pricing = document.getElementById("pricing");
      pricing?.scrollIntoView({ behavior: "smooth" });
    } else {
      setAuthType("login");
    }
  };

  useEffect(() => {
    if (user) {
      const { isActivePlan } = extranctPlanData(user.subscriptionDetails);
      if (isActivePlan) {
        setButtonLabel("Download App");
      }
    }
  }, [user]);

  return (
    <>
      <button
        className="btn-1"
        style={{
          /* Layout Properties */ top: "449px",
          left: "638px",
          width: "220px",
          height: "60px",
          /* UI Properties */
          background: "#52c4cf 0% 0% no-repeat padding-box",
          border: "1px solid #ffffff",
          borderRadius: "30px",
          opacity: "1",
        }}
        onClick={handleBtnClcik}
      >
        {buttonLabel}
      </button>
      <AuthUser authType={authType} setAuthType={setAuthType} />
    </>
  );
}
