import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-hot-toast";
import userStore from "../../stores/userStore";

const SignupModal = ({ isOpen, onClose, onHaveAccount }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const setUser = userStore((state) => state.setUser);
  const modalContentRef = useRef(null);
  const [passwordError, setPasswordError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.passwordConfirm) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://torofx.appsimagicallp.com/users/register",
        formData
      );
      toast.success("Signed up succesfully");
      onHaveAccount();
    } catch (error) {
      console.error("Signup failed:", error);
      toast.error("Signup failed");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const handleClickOutsideModal = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Signup Modal"
      className="login-modal"
      overlayClassName={"modal-overlay"}
    >
      {/* Modal Content */}
      <div
        className="modal-content signup-modal"
        onClick={(e) => e.stopPropagation()}
        ref={modalContentRef}
      >
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>Signup</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label style={{ color: "white" }}>Email*</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label style={{ color: "white" }}>Password*</label>
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label style={{ color: "white" }}>Confirm Password*</label>
            <input
              type="password"
              name="passwordConfirm"
              placeholder="Confirm your password"
              value={formData.passwordConfirm}
              onChange={handleChange}
              required
            />
          </div>
          {passwordError && <p className="error-message">{passwordError}</p>}

          <button
            type="submit"
            style={{
              background: "#09ffcf",
              opacity: "0.65",
              color: "black",
              width: "100%",
            }}
          >
            Sign up
          </button>
        </form>
        <span
          className="forgot-password"
          style={{ color: "#09ffcf", opacity: "0.65" }}
          onClick={onHaveAccount}
        >
          Already have account, Login?
        </span>
      </div>
    </Modal>
  );
};

export default SignupModal;
