// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/Assets/geom_light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/Assets/geom_light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public/Assets/geom_light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.menu li {
  margin-right: 15px;
}

.menu li:last-child {
  margin-right: 0;
}

.menu li a {
  color: white;
  text-decoration: none;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  font-family: "Geom";
}
.userIcon {
  margin: 0 50px 0 auto;
}

@media only screen and (max-width: 768px) {
  .userIcon {
    margin: 0 0 0 10px;
  }
  .login-btn {
    margin-right: 0 !important ;
  }
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .nav-link img {
    margin-left: 0;
  }

  .btn {
    margin: 0 10px 0 auto;
  }
}

@media (min-width: 768px) {
  .nav-link img {
    margin-left: 100px;
  }
}
@font-face {
  font-family: "Geom";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
       url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
  font-weight: 300;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,sCAAiC;EAAjC,iCAAiC;EACjC,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE,mBAAmB;EACnB;;iEAEoE;EACpE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".container {\n  width: 100%;\n  padding-right: 15px;\n  padding-left: 15px;\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.menu {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: space-between;\n}\n\n.menu li {\n  margin-right: 15px;\n}\n\n.menu li:last-child {\n  margin-right: 0;\n}\n\n.menu li a {\n  color: white;\n  text-decoration: none;\n  min-width: fit-content !important;\n  font-family: \"Geom\";\n}\n.userIcon {\n  margin: 0 50px 0 auto;\n}\n\n@media only screen and (max-width: 768px) {\n  .userIcon {\n    margin: 0 0 0 10px;\n  }\n  .login-btn {\n    margin-right: 0 !important ;\n  }\n}\n\n@media (max-width: 768px) {\n  .menu {\n    display: none;\n  }\n\n  .nav-link img {\n    margin-left: 0;\n  }\n\n  .btn {\n    margin: 0 10px 0 auto;\n  }\n}\n\n@media (min-width: 768px) {\n  .nav-link img {\n    margin-left: 100px;\n  }\n}\n@font-face {\n  font-family: \"Geom\";\n  src: url(\"../../../public/Assets/geom_light.woff2\") format(\"woff2\"),\n       url(\"../../../public/Assets/geom_light.woff\") format(\"woff\"),\n       url(\"../../../public/Assets/geom_light.otf\") format(\"opentype\");\n  font-weight: 300;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
