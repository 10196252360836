// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roundCircle {
  border: 2px solid #52c4cf;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: sticky;
  /* bottom: 10px; */
  right: 10px;
  z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Chat/Chat.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb","sourcesContent":[".roundCircle {\n  border: 2px solid #52c4cf;\n  background-color: white;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  position: sticky;\n  /* bottom: 10px; */\n  right: 10px;\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
