import React from "react";

const Desktop = () => {
  return (
    <div className="container">
      <div className="textBox1">
        <h1 className="title-txt" style={{ color: "white", width: "100%" }}>
          Exclusive to Desktop App
        </h1>
        <p
          className="text-width-lg"
          style={{
            fontSize: "18px",
            color: "white",
            marginBottom: "10px",
            color: "#FFFFFF",
            opacity: "0.7",
          }}
        >
          Ignite your trading success with our desktop app's unique features.
          Gain a competetive edge. harness real-time market data and stay
          informed through
          <br /> our live crypto news feed. Experience the power of informed
          trading like never before.
        </p>
      </div>
      <img
        className="laptop-img" style={{width:"60vw"}}
        src="/Assets/featured/Tprofx_1.png"
        alt="desktop"
      />
    </div>
  );
};

export default Desktop;
