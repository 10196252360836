import React, { useEffect } from "react";
import LoginModal from "../Login/LoginModal";
import SignupModal from "../Signup/Signup";
import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";

export default function AuthUser({ authType, setAuthType }) {
  const close = () => setAuthType("");

  return (
    <>
      {authType === "login" && (
        <LoginModal
          isOpen={authType ? true : false}
          onClose={close}
          onCreateAccount={() => {
            setAuthType("signup");
          }}
          onforgot={() => setAuthType("forgotPassword")}
        />
      )}
      {authType === "signup" && (
        <SignupModal
          isOpen={authType ? true : false}
          onClose={close}
          onHaveAccount={() => {
            setAuthType("login");
          }}
        />
      )}
      {authType === "forgotPassword" && (
        <ForgotPasswordModal isOpen={authType ? true : false} onClose={close} />
      )}
    </>
  );
}
