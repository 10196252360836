import React from "react";
import "./Carousel.css";
import MemberButton from "../MemberButton/MemberButton";

const Carousel = () => {
  return (
    <section className="video-section">
      <video
        src="Assets/hud-globe-background-2023-11-27-04-54-06-utc (1).mp4"
        autoPlay
        loop
        muted
        playsInline
      ></video>
      <div className="textBox1">
        <h3
          style={{
            fontWeight: "normal",
            fontSize: "30px",
            marginBottom: "10px",
          }}
        >
          Innovate Your Trading
        </h3>
        <h1 className="title-txt">
          The <span1>Complete Education </span1>Package
        </h1>
        <p className="text-base">
          Discover the power of knowledge with our cutting-edge education
          platform. Our custom-built mobile and desktop apps are designed to
          equip you with the skills and strategies needed to thrive in the
          exciting world of Forex and Crypto.
          <br />
          Unlock a world of opportunity with our comprehensive educational
          content, meticulously curated for growth.
        </p>
        <div className="membership-buttons">
          <MemberButton />
          {/* <button href="#" className="btn-2">
            Try for FREE!
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default Carousel;
