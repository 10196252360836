// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-form-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  position: absolute;
  padding: 20px;
}

.payment-form-container h2 {
  margin-top: 0;
}
.checkout-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate((-50%, -50%));
}
.checkout-loader span {
  height: 25px !important;
  width: 25px !important;
}

#submit {
  margin: 10px 0;
}

.error-message {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  row-gap: 10px;
}

.error-message button {
  border-radius: 20px;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .payment-form-container {
    display: inline;
    box-sizing: border-box;
    height: 100vh;
    background-color: white;
    overflow-y: scroll;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Payment/Payment.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,kCAAkC;AACpC;AACA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;IACf,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;EACpB;AACF","sourcesContent":[".payment-form-container {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  background-color: white;\n  width: 100%;\n  position: absolute;\n  padding: 20px;\n}\n\n.payment-form-container h2 {\n  margin-top: 0;\n}\n.checkout-loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate((-50%, -50%));\n}\n.checkout-loader span {\n  height: 25px !important;\n  width: 25px !important;\n}\n\n#submit {\n  margin: 10px 0;\n}\n\n.error-message {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  text-align: center;\n  height: 100%;\n  row-gap: 10px;\n}\n\n.error-message button {\n  border-radius: 20px;\n  padding: 10px 20px;\n}\n\n@media (max-width: 768px) {\n  .payment-form-container {\n    display: inline;\n    box-sizing: border-box;\n    height: 100vh;\n    background-color: white;\n    overflow-y: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
