// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/Assets/geom_light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/Assets/geom_light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/Assets/geom_light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @font-face {
  font-family: "DMSans";
  src: "url(../../../public/Assets/DMSans.ttf)" !important;
} */
/* @font-face {
  font-family: "DMSans";
  src: "url(../../../public/Assets/DMSans.ttf)" !important;
}

body {
  background: linear-gradient(to right, #134653, #134653);
  font-family: "DMSans", sans-serif !important;
} */

@font-face {
  font-family: "Geom";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
       url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
  /* font-weight: 300; */
  font-style: normal;
}

body {
  background: linear-gradient(to right, #134653, #134653);
  font-family: "Geom", sans-serif;
  /* font-weight: 300; */
}

#root {
  overflow-x: hidden;
}

.update-profile-modal {
  height: -moz-fit-content !important;
  height: fit-content !important;
}
span {
  color: #4EFCFF;
}
span1 {
  color: #4EFCFF;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;;;GAGG;AACH;;;;;;;;GAQG;;AAEH;EACE,mBAAmB;EACnB;;iEAE8D;EAC9D,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,uDAAuD;EACvD,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mCAA8B;EAA9B,8BAA8B;AAChC;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n/* @font-face {\n  font-family: \"DMSans\";\n  src: \"url(../../../public/Assets/DMSans.ttf)\" !important;\n} */\n/* @font-face {\n  font-family: \"DMSans\";\n  src: \"url(../../../public/Assets/DMSans.ttf)\" !important;\n}\n\nbody {\n  background: linear-gradient(to right, #134653, #134653);\n  font-family: \"DMSans\", sans-serif !important;\n} */\n\n@font-face {\n  font-family: \"Geom\";\n  src: url(\"../public/Assets/geom_light.woff2\") format(\"woff2\"),\n       url(\"../public/Assets/geom_light.woff\") format(\"woff\"),\n       url(\"../public/Assets/geom_light.otf\") format(\"opentype\");\n  /* font-weight: 300; */\n  font-style: normal;\n}\n\nbody {\n  background: linear-gradient(to right, #134653, #134653);\n  font-family: \"Geom\", sans-serif;\n  /* font-weight: 300; */\n}\n\n#root {\n  overflow-x: hidden;\n}\n\n.update-profile-modal {\n  height: fit-content !important;\n}\nspan {\n  color: #4EFCFF;\n}\nspan1 {\n  color: #4EFCFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
