import React from "react";
import LandingPage from "./Components/Home/LandingPage";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Membership from "./Components/Membership/Membership";
import Products from "./Components/Products/Products";
import Courses from "./Components/Courses/Courses";
import Chat from "./Components/Chat/Chat";
import LoginModal from "./Components/Login/LoginModal";
import Profile from "./Components/Profile/Profile";
import Payment from "./Components/Payment/Payment";
import ThankYouPage from "./Components/ThankYouPage/ThankYouPage";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import Disclaimer from "./Components/TermsAndConditions/Disclaimer";
import RefundPolicy from "./Components/TermsAndConditions/Refund";
import PrivacyPolicy from "./Components/TermsAndConditions/PrivacyPolicy";
import Download from "./Components/Download/Download";
import DataProtection from "./Components/TermsAndConditions/DataProtection";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/Membership" element={<Membership />}></Route>
          <Route path="/Products" element={<Products />}></Route>
          <Route path="/Programs" element={<Courses />}></Route>
          <Route path="/Chat" element={<Chat />}></Route>
          <Route path="/Profile" element={<Profile />}></Route>
          {/* <Route path="/checkout" element={<Payment />}></Route> */}
          <Route path="/success" element={<ThankYouPage />}></Route>
          <Route path="/terms" element={<TermsAndConditions />}></Route>
          <Route path="/disclamer" element={<Disclaimer />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
          <Route path="/refund" element={<RefundPolicy />}></Route>
          <Route path="/data-protection" element={<DataProtection />}></Route>
          <Route path="/Download" element={<Download />}></Route>
        </Routes>
      </BrowserRouter>
      <Toaster position="bottom-center" /> {/* Specify the position here */}
    </>
  );
}

export default App;
